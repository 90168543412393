import "./header-page-shared.scss";
import "./header-page-sidebyside.scss";
import "./header-page-sidebyside-flex.scss";
import "./header-page-textinset.scss";      // CAK demo
import "./header-page-textinset-ctas.scss"; // CAK demo
import "./header-page-wideimage.scss";
import "./header-page-stickingout.scss";
import "./header-page-search.scss";
import "./header-textual.css";

import * as dompack from "dompack";

dompack.register(".pageheader--sidebyside-flex", doInitFlexHeader);

let headernode;

function doInitFlexHeader(node)
{
  headernode = node;
  doUpdateHeaderState();
  window.addEventListener("resize", onResize);
}

function onResize()
{
  headernode.classList.remove("pageheader-wrapping");
  doUpdateHeaderState();
}

function doUpdateHeaderState()
{
  let text = headernode.querySelector(".pageheader__text");
  let imagewrapper = headernode.querySelector(".pageheader__imagewrapper");

  let wrapping = text.offsetTop != imagewrapper.offsetTop;
  // console.log("Wrapping", wrapping);
  headernode.classList.toggle("pageheader-wrapping", wrapping);
}

