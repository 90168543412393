import * as dompack from "dompack";

import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import { SplitDateField, SplitTimeField } from '@mod-publisher/js/forms/fields/splitdatetime';

import "@mod-zvi/webdesigns/shared/forms/forms-feedback-hacks.es";
import "@mod-zvi/webdesigns/shared/forms/forms-feedback-styling.scss";


// Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
import '@mod-publisher/js/forms/themes/neutral';

import './buttons.css';
import './buttons-extra.scss';
import '@mod-zvi/webdesigns/shared/forms/checkradio-buttonbar.css';
import '@mod-zvi/webdesigns/shared/forms/checkradio-native.scss';
import '@mod-zvi/webdesigns/shared/forms/checkbox-switch.scss';
import '@mod-zvi/webdesigns/shared/forms/textual-input.scss';

import './formlayout.scss';
import './pulldown.scss';

import './uploadfields.scss';

import './specials.scss';


// Enable forms and our builtin validation
forms.setup({ validate:true });

// Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

// Replaces date fields with a split version
dompack.register(".wh-form__date", node => new SplitDateField(node));
dompack.register(".wh-form__time", node => new SplitTimeField(node));
