/*
This library faded out the deeplinks/sharebuttons at the first position with a wide element
*/

import * as dompack from "dompack";

let deeplinkswrappernode = null;
let firstwideelement = null;
let currently_shown = true;

dompack.onDomReady(init);


function doCheckState()
{
  // FIXME: detect when deeplinkswrapper is hidden or not sticky.. in those cases showdeeplinks must be TRUE

  let showdeeplinks = true;
  if (firstwideelement)
    showdeeplinks = deeplinkswrappernode.getBoundingClientRect().bottom < firstwideelement.getBoundingClientRect().top - 50;


  if (showdeeplinks != currently_shown)
  {
    //console.log("deeplinkswrappernode", deeplinkswrappernode);
    deeplinkswrappernode.classList.toggle("hide_deeplinks", !showdeeplinks);
    currently_shown = showdeeplinks;
  }
}


function init()
{
  console.log("[PageGrid-fadedeeplinks] init");

  deeplinkswrappernode = document.querySelector(".deeplinks-wrapper");
  if (!deeplinkswrappernode)
    return; // nothing to do


  let detailnodes = document.querySelectorAll("details");
  for (let node of detailnodes)
  {
    node.addEventListener("toggle", evt => onAfterToggleDetails(evt));
  }


  firstwideelement = findFirstVisibleWideElementOnPage();

  window.addEventListener("scroll", evt => doCheckState(evt));
}

function onAfterToggleDetails(evt)
{
  // console.log("<details> toggled");
  firstwideelement = findFirstVisibleWideElementOnPage();
}




function findFirstVisibleWideElementOnPage()
{
  let contentwrapper = document.querySelector(".page__contentarea--rtddoc");
  let node = findFirstVisibleWideElement(contentwrapper);
  // console.log("First wide element is", node);
  return node;
}


function findFirstVisibleWideElement(rtdcontentnode)
{
  let foundnode;
  let node = rtdcontentnode.firstChild;
  while(node)
  {
    // "needwide", "preferwide"
    let preference = getComputedStyle(node).getPropertyValue("--content-squeeze-preference").trim();

    if (preference == "needwide" || preference == "preferwide")
    {
      foundnode = node;
      break;
    }

    // An accordion visually usually takes the default width,
    // but can have widgets within which take a larger (or full) width.
    if (node.classList.contains("widget-accordion"))
    {
      foundnode = findFirstVisibleWideElementWithinAccordion(node);
      if (foundnode)
        return foundnode;
    }

    node = node.nextElementSibling;
  }

  return foundnode;
}


function findFirstVisibleWideElementWithinAccordion(node)
{
  let items = node.querySelectorAll(".widget-accordion__item");
  for (let item of items)
  {
    if (item.tagName = "DETAILS")
    {
      // the content to expand is hidden within the shadowroot of the <details> element
      // This means we cannot measure it and have to check the "open" attribute of the <details> to see the state.
      if (!item.hasAttribute("open"))
        continue;
    }
    else
    {
      // Find the wrapper used for showing/hiding/clipping
      let contentwrapper = item.querySelector(".widget-accordion__item__body");
      if (contentnode.clientHeight == 0)
        continue;
    }


    let contentcontainer = item.querySelector(".widget-accordion__item__body__content");

    let resultnode = findFirstVisibleWideElement(contentcontainer);
    if (resultnode)
      return resultnode; // return the found wide element
  }

  return null;
}
