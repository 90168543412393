import "./multiselect.scss";
import * as dompack from "dompack";
import * as browser from 'dompack/extra/browser';

// import { createPopper } from '@popperjs/core';
// import { computePosition, shift, flip, offset } from '@floating-ui/dom';

// multiselect panel / multiselect pulldown

window.__multi = [];

/*

29-dec-2023:
- trigger/valuedisplay now is a <button> with aria-expanded and aria-controls

Behaviour:

- klik buiten pulldown
  - sluit dropdown
  - herstel focus naar pulldown component
    als OF focus in de dropdown was OF erbuiten geklikt is maar niet op een focusable element
    (evt.relatedTarget is dan null - de browser gebruikt dan de <body> als fallback focus activeElement)

- focus op pulldown + ArrowDown -> open dropdown

- esc -> sluit dropdown - herstel focus naar bijbehorende pulldown component

- openen van dropdown set nu focus op 1e item (inplaats van dropdown container)

ADDME: speciale role?
ADDME: pijltoets navigate??

*/


/*
dompack.onDomReady(function() {

  let triggernode = document.querySelector("poppy-toggle");
  let dropdown = document.querySelector("poppy-dropdown");
  triggernode.addEventListener("click", doToggle);
  document.body.appendChild(dropdown);

  // this.popperInstance = Popper.createPopper(this.triggernode, this.panel_anchor);
  this.popperinstance = createPopper(triggernode, dropdown,
        {
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          adaptive: true, // true by default
        },
      },
    ],
  });

});

function doToggle()
{
}
*/


window.addEventListener("resize", repositionDropdowns);
window.addEventListener("scroll", repositionDropdowns, { passive: true });


function getObscuredTopHeight()
{
  let menubar = document.querySelector(".header-menubar");
  return menubar.offsetHeight; // getBoundingClientRect().height
}



function repositionDropdowns(evt)
{
  console.info("Repositioning due to %s event", evt.type);

  for (let instance of window.__multi)
  {
    if (instance.node_main.contains(document.activeElement))
      instance.reposition();
  }
}



export class FilteredMultiselect
{
  constructor(node, options)
  {
    this.restorevalues = [];


    this.node_main = node; // .spc-multiselect

    this.panel_anchor = node.querySelector(".multiselect__panel__container");
    this.panel_content = node.querySelector(".multiselect__panel");
    // console.log("PANEL", this.panel_anchor);
    this.form = dompack.closest(this.panel_anchor, "form");

    let firstcheckbox = this.panel_anchor.querySelector('input[type="checkbox"]');
    if (!firstcheckbox)
    {
      console.error("No checkbox found.");
      return;
    }
    this.fieldname = firstcheckbox.name;

    this.summarypanel = this.form.querySelector(`[data-summaryfor="${this.fieldname}"]`);
    this.emptytext = this.form.querySelector(`[data-emptytextfor="${this.fieldname}"]`);

    this.itemspanel = this.panel_anchor.querySelector(".multiselect__panel__items");
    this.triggernode = this.node_main.querySelector(".multiselect__panel__trigger");

    // NOTE: for Safari it's too late to use evt.preventDefault() in the "keyup" event,
    //       so we must use "keydwon". (otherwise opening the pulldown using "ArrowDown" will scroll te page)
    this.triggernode.addEventListener("keydown", evt => this.doCheckKeyboardEvent(evt));
    // this.panel_anchor.addEventListener("keydown", evt => this.doCheckKeyboardEvent(evt));

    this.triggernode.addEventListener("focusout", evt => this.doCheckFocus(evt));
    this.panel_anchor.addEventListener("focusout", evt => this.doCheckFocus(evt)); // wrapper for positioning and focus


//document.body.appendChild(this.panel_anchor);



/*
// this.popperInstance = Popper.createPopper(this.triggernode, this.panel_anchor);
this.popperinstance = createPopper(this.triggernode, this.panel_anchor,
      {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        adaptive: true, // true by default
      },
    },
  ],
});

console.warn(this.popperinstance);
*/


/*
this.popperinstance = createPopper(this.triggernode, this.panel_anchor
  , { preventOverflow: true
    , flip:            true
    , placement: "bottom"
    // , boundary:        document.querySelector("")
  });
*/
    /*
    console.info( "FilteredMultiSelect nodes"
                , { node_multiselect: node
                  , node_parentform:  this.form
                  , fieldname:        this.fieldname
                  , summarypanel:     this.summarypanel
                  , emptytext:        this.emptytext
                  , itemspanel:       this.itemspanel
                  , triggernode:      this.triggernode
                  });
    */

    this.panel_content.addEventListener("keydown", evt => this.doHandleOptionsKeyNavigation(evt));
    this.panel_anchor.querySelector(".multiselect__panel__ok").addEventListener("click", evt => this.setDropdownState(false, false));
    this.panel_anchor.querySelector(".multiselect__panel__clear").addEventListener("click", evt => this.doClearSelection(evt));

    this.summarypanel.addEventListener("click", evt => this.doRemoveSelectedSummaryItem(evt));

    // The pullbar component bar works as toggle for the dropdown panel
    this.triggernode.addEventListener("click", evt => this.doToggleOptionsPanel(evt, false));
    this.triggernode.addEventListener("keypress", evt => this.doToggleOptionsPanel(evt, true));

    // FIXME: WILL FAIL for multiple multiselect fields..
    // this.triggernode.addEventListener("change", evt => this.onChangeVisibility(evt));

    this.itemspanel.addEventListener("change", evt => this.onChange(evt));
    this.form.addEventListener("reset", evt => this.onReset(evt)); // needed bercause a form reset doesn't trigger the change event(?)


    this.searchnode = node.querySelector(".multiselect__panel__search");

    if (this.searchnode)
    {
    // if (browser.getName() != "ie") // IE11 workaround
    //  this.searchnode.addEventListener("keypress", evt => this.doSearch(evt));
   // else
      this.searchnode.addEventListener("input", evt => this.doSearch(evt));
    }

/*
    let cancelbutton = this.panel_anchor.querySelector(".multiselect__panel__cancel")
    if (cancelbutton)
      cancelbutton.addEventListener("click", evt => this.doCancelChanges(evt));
*/

    this.refreshSummary();

    /*
    console.log({ panel:        this.panel_anchor
                , form:         this.form
                , fieldname:    this.fieldname
                , searchnode:   this.searchnode
                , summarypanel: this.summarypanel
                , triggernode:  this.triggernode
                });
    */
  }

  doClearSelection()
  {
    // deselect all checkboxes within the multiselect panel
    let cbs = this.panel_content.querySelectorAll('input[type="checkbox"]');
    for (let cb of cbs)
      cb.checked = false;

    // ensure the summary of selected items is updated
    // let multiselect = dompack.closest(evt.target, ".multiselect");
    // updateMultiSelectByNode(this.node_main);

    // FIXME: triggering a submit might not always be the right option..
    //        Find another way to communicatie change events (for FilteredOverview which likes to do realtime updates)
    let form = dompack.closest(this.node_main, "form");
    if (form)
      dompack.dispatchCustomEvent(form, "submit", { bubbles: false, cancelable: true});
  }

  // event delegation handler for all summaryitem__remove buttons
  doRemoveSelectedSummaryItem(evt)
  {
    let removebutton = evt.target.closest(".multiselect__summaryitem__remove");
    if (!removebutton)
      return;

    let summaryitem = evt.target.closest(".multiselect__summaryitem");
    let checkbox_id = summaryitem.dataset.relatedcheckbox;
    // console.log("checkbox", checkbox_id);
    let checkbox = document.getElementById(checkbox_id);
    if (!checkbox)
    {
      console.error(`Couldn't find corresponding checkbox with id ${checkbox_id}`);
      return;
    }

    checkbox.checked = false;

    this.refreshSummary();
  }


  doHandleOptionsKeyNavigation(evt)
  {
    if (evt.key == "ArrowUp")
    {
      let options = Array.from(this.panel_content.querySelectorAll('input[type="checkbox"]'));
      let idx = options.indexOf(document.activeElement);

      if (idx > 0)
      {
        evt.preventDefault(); // prevent page scrolling
        evt.stopPropagation();
        options[idx-1].focus();
      }
    }

    if (evt.key == "ArrowDown")
    {
      let options = Array.from(this.panel_content.querySelectorAll('input[type="checkbox"]'));
      let idx = options.indexOf(document.activeElement);

      if (idx < options.length - 1)
      {
        evt.preventDefault(); // prevent page scrolling
        evt.stopPropagation();
        options[idx+1].focus();
      }
    }

    if (evt.key == "Escape")
    {
      evt.preventDefault();
      evt.stopPropagation();

      // We got the key event within our component DOM, so we have focus.
      // This means we most move our focus back to the triggernode the (pulldown).
      // This MUST be done before closing the pulldown because hiding the content which includes
      // the focused element (document.activeElement) will move the focus.
      this.triggernode.focus();
      // this.closeDropdown();
      this.setDropdownState(false, true);
    }
  }



  onChange(evt)
  {
    // console.log("[FilteredMultiSelect] onChange", evt.type);
    this.refreshSummary();
  }

  onReset(evt)
  {
    // NOTE!! we receive the reset BEFORE the form fields are actually reset - so we cannot read our new value yet at this point
    // console.log(evt.type, evt.target, this.node_main);
    // this.refreshSummary();
  }

  doCheckKeyboardEvent(evt)
  {
    if (evt.key == "ArrowDown")
    {
      evt.preventDefault(); // prevent page scrolling
      evt.stopPropagation();

      this.setDropdownState(true, true);
    }
    else if (evt.key == "Escape")
    {
      evt.preventDefault();
      evt.stopPropagation();

      // We got the key event within our component DOM, so we have focus.
      // This means we most move our focus back to the triggernode the (pulldown).
      // This MUST be done before closing the pulldown because hiding the content which includes
      // the focused element (document.activeElement) will move the focus.
      this.triggernode.focus();
      // this.closeDropdown();
      this.setDropdownState(false, true);
    }
  }

  doToggleOptionsPanel(evt, visiblefocus)
  {
    let visible = this.panel_anchor.hasAttribute("hidden");
    this.setDropdownState(visible, visiblefocus);
  }

  doCheckFocus(evt)
  {
    /*
    console.info(
        "event type",    evt.type, "\n"
      , "target",        evt.target, "\n"
      , "relatedTarget", evt.relatedTarget, "\n"
      , "activeElement", document.activeElement
      );
    */

    // no new focus target? Then restore the pulldown (trigger) as focused element.
    if (!evt.relatedTarget)
    {
      this.triggernode.focus();
      this.closeDropdown();
      return; // clicking, but not in any focusable region of the page
    }

    // if (!evt.relatedTarget.closest(".spc-multiselect"))
    // if (!evt.relatedTarget || !evt.relatedTarget.closest(".multiselect__panel__trigger, .multiselect__panel__container"))
    if (!this.triggernode.contains(evt.relatedTarget) & !this.panel_anchor.contains(evt.relatedTarget))
      this.closeDropdown();
  }

  closeDropdown() // for external usage
  {
    this.setDropdownState(false, false);
  }

  setDropdownState(visible, visiblefocus)
  {
    if (visible)
    {
      this.panel_anchor.removeAttribute("hidden");
      this.triggernode.setAttribute("aria-expanded", "true");

      //this.popperinstance.update();
      this.reposition();

      /*
      - focus option focusVisible is supported by Firefox 104 (aug 2022)
      - Chrome by default applies a visible focus when focus() is called on a checkbox
      - Safari and Firefox don't apply visible focus (but Firefox DOES support focusVisible)

      Track support for focusVisible:
      - Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=1317039
      - Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1765083
      - Safari: https://bugs.webkit.org/show_bug.cgi?id=242456
      */
      let first_checkbox = this.panel_anchor.querySelector("input");
      if (first_checkbox)
        first_checkbox.focus({ preventScroll: true
                             , focusVisible:  visiblefocus // when trigger through keyboard interaction we want to show the (visible-)focus
                             });
    }
    else
    {
      this.panel_anchor.setAttribute("hidden", "");
      this.triggernode.setAttribute("aria-expanded", "false");

      // this.popperinstance.update();
    }
  }


  reposition()
  {
    console.log("[FilteredMultiSelect]", this.fieldname);

    let distance_to_viewport_top = 20 + getObscuredTopHeight();
    let distance_to_viewport_bottom = 20;


    // Clear all styling we have used previously for positioning/sizing
    // so we can correctly measure how much space the dropdown needs.
    this.panel_anchor.style.position = "";
    this.panel_anchor.style.top = "";
    this.panel_content.style.bottom = "";
    this.panel_content.style.minHeight = "";
    this.panel_content.style.maxHeight = "";


    let viewport_height = document.documentElement.clientHeight;
    let b_trigger = this.triggernode.getBoundingClientRect();
    let trigger_bottom = b_trigger.y + b_trigger.height;
    let free_space_bottom = viewport_height - trigger_bottom - distance_to_viewport_bottom;
    let free_space_above = b_trigger.y - 1 - distance_to_viewport_top;

    this.panel_content.style.maxHeight = free_space_above + "px";

    /*
    // Calc space below pulldown
    console.log(
          { bodybounds: document.body.getBoundingClientRect()
          , triggerbounds: this.triggernode.getBoundingClientRect()
          , viewport_height: viewport_height
          , trigger_bottom: trigger_bottom
          , free_space_bottom: free_space_bottom
          });

    console.info("panel_content", this.panel_content);
    */
    this.panel_content.style.maxHeight = free_space_bottom + "px";

/*
1. position below
2. if due to min-height still larger than available space
   - compare available space above en below.
     if above more space -> position above
     otherwise below --- but limit height below the min-space (override with inline style)
*/

    if (this.panel_content.offsetHeight > free_space_bottom)
    {
      this.panel_anchor.style.position = "absolute";
      this.panel_anchor.style.top = "0";

      this.panel_content.style.bottom = "0";

      this.panel_content.style.maxHeight = free_space_above + "px";

      // console.info("popover position flip (positioning above instead of below");
    }

/*
computePosition(this.triggernode, this.panel_anchor, {
            placement: 'bottom',
            middleware: [offset(12),shift(),flip()],
        }).then(({x, y}) => {
                Object.assign(this.panel_anchor.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });
            });
*/
  }



  /*
  doCancelChanges()
  {
    let options = this.panel_anchor.querySelectorAll('input[type="checkbox"]');
    for (let option of Array.from(options)) // Safari needs us to make it an array
      option.checked = this.restorevalues.indexOf(option.value) > -1;

    this.triggernode.checked = false;

    // Send an change event because the browser doesn't send one when code changes the checkbox states
    dompack.dispatchCustomEvent(this.triggernode, "wh:change",
        { bubbles: true
        , cancelable: false
        // , detail: { container: widgetnode ? dompack.closest(widgetnode, ".widgetsblock__contents" ) : null }
        });
  }

  onChangeVisibility(evt)
  {
    if (!evt.target.checked)
      this.refreshSummary();
    else
    {
      this.restorevalues = this.getValue();
      // console.log("restorevalues", this.restorevalues);
    }
  }
  */

  getValue()
  {
    let values = [];
    let options = this.panel_anchor.querySelectorAll('input[type="checkbox"]');
    for (let option of Array.from(options)) // Safari needs us to make it an array
    {
      if (option.checked)
        values.push(option.value);
    }
    return values;
  }

  getSelection()
  {
    let values = [];
    let options = this.panel_anchor.querySelectorAll('input[type="checkbox"]');
    for (let option of Array.from(options)) // Safari needs us to make it an array
    {
      if (option.checked)
      {
        let optionwrapper = option.closest(".optionlist__item");

        let title = "";
        if (option.dataset.title)
          title = option.dataset.title;
        else
        {
          if (!optionwrapper)
            console.error("missing optionwrapper");
          title = optionwrapper.querySelector("label").innerText;
        }

        let badge = optionwrapper.querySelector(".badge");

        values.push({ id:        option.id // for <label for="{id}">
                    , value:     option.value
                    , title:     title
                  //, badgetext: option.dataset.badgetext
                    , badgetext: badge ? badge.innerText : ""
                    });
      }
    }
    return values;
  }

  doSearch(evt)
  {
    let query = evt.target.value.toLowerCase();

    let options = this.panel_anchor.querySelectorAll('input[type="checkbox"]');
    for(let option of options)
    {
      let title = "";
      if (option.dataset.basetitle)
        title = option.dataset.basetitle;
      else
        title = option.nextSibling.nextSibling.innerText;

      let matches = title.toLowerCase().indexOf(query) > -1;

      option.parentNode.classList[matches ? "remove" : "add"]("multiselect__hidden");

      // console.log(matches, labeltext);
    }
  }

  createSummaryItem(item)
  {
    //<button type="button" class="multiselect__summaryitem__remove"></button>

    /*
    return <div class="multiselect__summaryitem" data-value={item.value}>
            <label class="multiselect__summaryitem__remove"
                   for={item.id}></label>
            <div class="multiselect__summaryitem__title">{item.title}</div>
            {item.badgetext != "" && <div class="multiselect__summaryitem__badge">{item.badgetext}</div>}
          </div>;
    */

    /*
    return <button class="multiselect__summaryitem"
                   type="button"
                   data-value={item.value}
                   data-relatedcheckbox={item.id}
                   aria-label={"Verwijder " + item.title + " (" + item.badgetext + " matches)"}
                   >
            <span class="multiselect__summaryitem__remove"></span>
            <div class="multiselect__summaryitem__title">{item.title}</div>
            {item.badgetext != "" && <div class="multiselect__summaryitem__badge">{item.badgetext}</div>}
          </button>;
    */

    /*
    NOTES:
    - The remove button will also include the title op the option it will remove within the aria-label.
      This way when navigating through the focusable elements in the page the screenreader will tell them which option
      will be removed by using the button.
    */
    return <div class="multiselect__summaryitem"
                type="button"
                data-value={item.value}
                data-relatedcheckbox={item.id}
                >
            <button class="multiselect__summaryitem__remove"
                    aria-label={"Verwijder " + item.title}
                    type="button"></button>
            <div class="multiselect__summaryitem__title">{item.title}</div>
            {item.badgetext != "" && <div class="multiselect__summaryitem__badge">{item.badgetext}</div>}
          </div>;
  }

  refreshSummary()
  {
    if (!this.summarypanel)
      return;
/*
    // Don't update while panel is opened. The user may still revert by clicking close instead of ok.
    if (this.triggernode.checked)
      return;
*/
    let selection = this.getSelection();
    // console.log("%c[FilteredMultiSelect]", "background-color: #FFAAAA; color: #000000; padding: 2px 10px;", "refreshSummary", selection);

    if (this.emptytext)
      this.emptytext.classList[selection.length > 0 ? "add" : "remove"]("multiselect__emptytext--hidden");

    // let frag = document.createDocumentFragment();
    let items = selection.map(this.createSummaryItem);
    this.summarypanel.innerHTML = "";
    for(let item of items)
      this.summarypanel.appendChild(item);
  }
}


dompack.register(".spc-multiselect", initMultiSelectPanel);



function initMultiSelectPanel(node)
{
  window.__multi.push( new FilteredMultiselect(node) );
}
