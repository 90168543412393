import * as dompack from "dompack";
import "./skiplinks.css";
import * as domfocus from 'dompack/browserfix/focus';


// distance we want to keep to the sticky navigationbar when scrolling to an element
let tonavbardistance = 0; // we already have some whitespace between the menubar and pageheader


dompack.onDomReady(init);


function init()
{
  initSkipLinks();
  initSkipToResults();
}



function initSkipToResults()
{
  let button = document.querySelector(".skipbutton--toresults");
  if (!button)
    return;

  button.addEventListener("click", doSkipToResults);
}

function doSkipToResults(evt)
{
  evt.preventDefault(); // prevent the browser from scrolling and switching focus back to the <body>

  // We could let the browser handle scrolling to the #results.
  // However if a small part of the feedbackx or results are already in view,
  // most browsers won't scroll. This means there will be no feedback that something happened.
  let resultscontainer = document.getElementById("results");

  // FIXME: if starting with a <h2> then focus the whole #results container?

  let items = resultscontainer.querySelectorAll("[data-filterdata]");
  let visible_item = null;
  for(let item of items)
  {
    if (item.clientHeight > 0)
    {
      visible_item = item;
      break;
    }
  }

  let focus_item = visible_item ?? resultscontainer;

  // If the element we want to focus doesn't currently support focus yet give it a tabindex of -1
  // (so it allows programmable focus)
  // if (!domfocus.canFocusTo(focus_item))
  if (!canAcceptFocus(focus_item))
    focus_item.setAttribute("tabindex", "-1");

  focus_item.focus(
            { preventScroll: true // whe'll handle scrolling ourselves
            , focusVisible:  true // when trigger through keyboard interaction we want to show the (visible-)focus
            });


  let obscuredareaheight = getObscuredAreaHeight();
  // scrolltonode.style.scrollMarginTop = (obscuredareaheight + tonavbardistance) + "px";
  // scrolltonode.scrollIntoView({ behavior: "smooth" });
  let results_top = resultscontainer.getBoundingClientRect() + document.documentElement.scrollTop;

  document.documentElement.scrollTo(
          { top:      results_top - (obscuredareaheight + tonavbardistance)
          , behavior: "smooth"
          });
}


// function canFocusTo(node: Element) { //returns if a -visible- node is focusable (this function does not check for visibility itself)
function canAcceptFocus(node)
{
  // try {
    // if ((node as HTMLElement).contentEditable === "true")
    if (node.contentEditable === "true")
      return true;

    // http://dev.w3.org/html5/spec-preview/editing.html#focusable
    // if ((node as HTMLElement).tabIndex == -1) //explicitly disabled
    if (node.tabIndex == -1) //explicitly disabled
      return false;

    return (parseInt(node.getAttribute('tabIndex') || "") >= 0) //we cannot read the property tabIndex directly, as IE <= 8 will return '0' even if the tabIndex is missing
      //even then: a[name] reports tabIndex 0 but has no getAttribute('tabIndex') so be prepared if you try to fix this..

      // || (["A", "LINK"].includes(node.nodeName) && (node as HTMLLinkElement).href)
      // || (!(node as HTMLInputElement).disabled && (["BUTTON", "SELECT", "TEXTAREA", "COMMAND"].includes(node.nodeName)
      // || (node.nodeName == "INPUT" && (node as HTMLInputElement).type != "hidden")));

        || (["A", "LINK"].includes(node.nodeName) && node.href)
        || (!node.disabled && (["BUTTON", "SELECT", "TEXTAREA", "COMMAND"].includes(node.nodeName)
        || (node.nodeName == "INPUT" && node.type != "hidden")));

  // } catch (e) {
    // return false; //the code above may fail eg on IE11 if it's a Flash object that'ss still loading
  // }
}


function initSkipLinks()
{
  let button = document.querySelector(".skiplinksbutton");
  if (!button)
    return; // not a normal page - may be a widget preview?

  button.addEventListener("click", doSkipLinks);

  let pageheader = document.querySelector(".pageheader");
  let main = document.querySelector("main");

  let focusnode = pageheader ?? main;
  focusnode.setAttribute("id", "skiplinkstarget");
}

function doSkipLinks(evt)
{
  evt.preventDefault();

  let pageheader = document.querySelector(".pageheader");
  let main = document.querySelector("main");
/*
  if (pageheader)
  {
  let focusable_nodes = domfocus.getFocusableComponents(this.options.node_results);

  }
*/
  let focusnode = pageheader ?? main;
  let scrolltonode = pageheader ?? main;


  if (!focusnode.hasAttribute("tabindex"))
    focusnode.setAttribute("tabindex", "-1"); // allow focus, but not in tab order

  console.log("Set focus to", focusnode);
  console.log("Scroll to", scrolltonode);

  focusnode.focus(
          { preventScroll: true
          , focusVisible:  true // when trigger through keyboard interaction we want to show the (visible-)focus
          });


/*
      if (focusable_nodes.length > 0)
      {
        // NOTE: switching contentEditable is a hack to trigger the :focus-within
        //       so when using keyboard navigation on the pagination bar
        //       we can show the focus has moved to the first result.
        //       See https://stackoverflow.com/questions/69281522/apply-focus-visible-when-focusing-element-programatically
        // FIXME: .contentEditable hack not verified on all browsers
        // FIXME: when whether .contentEditable switching kills eventListeners
        // FIXME: maybe temporary setting a temporary focus-within classname and remove it upon losing focus is better
        // NOTE:  discussion on having an focus() option for this: https://discourse.wicg.io/t/ability-to-explicitly-set-focus-visible-from-focus/5695
        focusable_nodes[0].contentEditable = true;
        focusable_nodes[0].focus();
        focusable_nodes[0].contentEditable = false;
      }
*/

  let obscuredareaheight = getObscuredAreaHeight();
  scrolltonode.style.scrollMarginTop = (obscuredareaheight + tonavbardistance) + "px";
  scrolltonode.scrollIntoView({ behavior: "smooth" });
}


function getObscuredAreaHeight()
{
  // NOTE: That height of the menu obscuring part of the content will change when we scroll.
  //       This means we cannot simply measure the height of the menu here..
  // (or use the height + the negatice top)


  let menubar = document.querySelector(".header-menubar");
  let cstyle = window.getComputedStyle(menubar);
  if (cstyle.position == "fixed" || cstyle.position == "sticky")
  {
    // Compensate for part of the viewport being covered
    obscuredareaheight = menubar.clientHeight;
  }

  //console.info("obscuredareaheight", obscuredareaheight);

  return obscuredareaheight;
}
