import * as dompack from "dompack";
import "./multiselect.es";
import "./multiselect.scss";
import "./multiselect-summary.scss";

/*
This will become V2 of multiselect

Design pillars:
- accessibility (hidden, role="group", )


..
- Use hidden attribute

- summaryfor changed to data-summaryfor
- emptytextfor changed to data-emptytextfor
- option input data-basetitle -> data-title

*/


document.addEventListener("change", evt => doCheckForMultiSelectChange(evt));


function getTitleForCheckbox(elemid)
{
  if (elemid == "")
    return "";

  let labelnode = document.querySelector('label[for="'+elemid+'"]:not(:empty)');
  if (labelnode)
    return labelnode.textContent;
}

function doCheckForMultiSelectChange(evt)
{
  let panel = dompack.closest(evt.target, ".multiselect__panel");
  if (!panel)
    return;

  //window.flup = evt.target;

  let multiselect = dompack.closest(evt.target, ".multiselect");
  updateMultiSelectByNode(multiselect);
}

function updateMultiSelectByNode(multiselect)
{
  return; // NOT USED ANYMORE

//  console.info("updateMultiSelectByNode", multiselect);

//  let field = targetnode.form[targetnode.name];

  // let multiselect = dompack.closest(targetnode, ".multiselect");
  let valuenode = multiselect.querySelector(".multiselect__valuedisplay__title");

  let cbnodes = multiselect.querySelectorAll('input[type="checkbox"]');

  let titles = [];
  for (let node of cbnodes)
  {
    if (node.checked)
    {
      let title = getTitleForCheckbox(node.id);
      titles.push(title != "" ? title : "??");
    }
  }

  if (titles.length == 0)
    valuenode.textContent = valuenode.getAttribute("data-emptytext");
  else
  {
    let fixedtitle;
    if (titles.length > 1)
      fixedtitle = valuenode.getAttribute("data-multitext");
    else
      fixedtitle = valuenode.getAttribute("data-singletext");

    if (fixedtitle != "")
    {
      fixedtitle = fixedtitle.replace("[amount]", titles.length);
      valuenode.textContent = fixedtitle;
    }
    else
      valuenode.textContent = titles.join(", ");
  }
}



export function refreshAll()
{
  let multiselects = document.querySelectorAll(".multiselect");
  for (let mscontainer of multiselects)
    updateMultiSelectByNode(mscontainer);
}

dompack.onDomReady(refreshAll);
