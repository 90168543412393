import * as dompack from "dompack";
import "./navigationpath.scss";


export function rewriteNavigationPath(pathitems)
{
  console.log("rewriteNavigationPath", pathitems);

  // Rewrite all navigation paths (crumbpaths) on the page
  let navpathcontainers = document.querySelectorAll(".navpath"); // FIXME: have better generic name for navpath container
  if (navpathcontainers.length == 0)
    return; // nothing to do

  let navpath_content = createNavigationPathContent(pathitems);

  // Replace the content of all navigation path containers
  for (let container of navpathcontainers)
  {
    container.innerHTML = "";
    container.appendChild(navpath_content);
  }


}



/** @short generate DOM for the navigationpath
 *  @param path array with navigation path items
 *  @param path.title
 *  @param path.link
 */
export function createNavigationPathContent(pathitems)
{
  let navigationpath = document.createDocumentFragment();

  for(let idx = 0; idx < pathitems.length; idx++)
  {
    let item = pathitems[idx];
    let itemnode;

    if (idx == pathitems.length - 1) // last item?
    {
      itemnode = <span class="navpath__item crumbpath--currentpage">{item.title}</span>;
      navigationpath.appendChild(itemnode);
    }
    else
    {
      if (item.link)
        itemnode = <a class="navpath__item" href="[link]">{item.title}</a>;
      else
        itemnode = <span class="navpath__item">{item.title}</span>;

      let seperatornode = <span class="navpath__seperator"></span>

      navigationpath.appendChild(itemnode);
      navigationpath.appendChild(seperatornode);
    }

    console.log(idx, item, itemnode);
  }

  return navigationpath;
/*
  [forevery navigationpath]
    [if last]
      <span class="navpath__item crumbpath--currentpage">[title:value]</span>[! :value to prevent the headerdescription breaklines being converted to <br /> tags !]
    [else]
      [if link]
        <a class="navpath__item" href="[link]">[title:value]</a>
      [else]
        <span class="navpath__item">[title:value]</span>
      [/if]
    [/if]
    [![if not last]&raquo;[/if]!]
    [if not last]<span class="navpath__seperator"></span>[/if]
  [/forevery]
*/
}
