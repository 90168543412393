import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-zvi/webdesigns/shared/components/filteredoverview";
import { onExpandFilters, onCollapseFilters } from "@mod-zvi/webdesigns/shared/components/filteredoverview/filter-dialog";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";

import "./knowledgebase.scss";


dompack.register("html.page--knowledgebase .acoifilterpage-filters", initSearchPage);


let filterform;
let resultscontainer;

function initSearchPage(formnode)
{
  filterform = formnode;
  resultscontainer = document.querySelector(".acoifilterpage__results");

  let text = document.querySelector(".acoifilterpage").getAttribute("data-hiddenfilters");
  let hiddenfilters = JSON.parse(text);

  let settings =
          { trackingid:             "filtered_kennisbank" // name for analytics (and debug)
          , statestorageid:         "filtered_kennisbank" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".spc-tile")
       // , getfloatingteasertext:  getFloatingTeaserText

          , hiddenfilters:          hiddenfilters

          , method:                 "rpc"
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , rpcsearchfunction:      "GenericSearch"
          , getfiltersforrpc:       getFiltersForRPC
          , onafterrefresh:         onAfternewResults

          , resultsperpage:         20

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters

          , debug:                  false
          , debug_rpc:              false
          };

  console.log("initSearchPage", settings);

  window.filteredoverview_kennisbank = new FilteredOverview(formnode, settings);

// FIXME!! only if a textual search is active
  if (window.filteredoverview_kennisbank.isFilterActive())
    window.filteredoverview_kennisbank.refreshResults(); // do initial search


//onExpandFilters();

/*
  if (window.filteredoverview_publications.isFilterActive())
    startOverview();
  else
  {
    // since we are using the default filters we can use the publications statically published into the page
    window.filteredoverview_publications.setFeedback(whintegration.config.obj.publicationsoverview.resultscount);
    enrichPublicationDetailsPageLinks();
  }
*/
}

function onAfternewResults()
{
  let instance = window.filteredoverview_kennisbank;

  //console.log(instance.filters);

  let resultscount = instance.resultscount;
  let query = instance.filters.query        ?? "";
  let thema = instance.filters_titles.thema ?? "";

  let feedback_text = "";
  // FIXME: geef een veld door in instance.last_rpcresponse voor aantal resultaten zonder te filteren op pagina type??

  if (resultscount == 0 && query == "")
  {
    feedback_text = `Type a.u.b. in bovenstaand zoekveld waar u naar wil zoeken.`;
  }
  else
  {
    let text_amount;
    if (resultscount > 1)
      text_amount = `Er zijn <strong>${resultscount} resultaten</strong>`;
    else if (resultscount == 1)
      text_amount = `Er is één resultaat`;
    else
      text_amount = `Er zijn geen resultaten`;

    let text_describe_query;
    if (query != "" && thema != "")
      text_describe_query = `voor "${query}" en thema "${thema}"`;
    else if (query != "")
      text_describe_query = `voor "${query}"`;
    // NOTE: we always require an query...

    feedback_text = `${text_amount} ${text_describe_query} binnen de kennisbank.`;
  }

  document.querySelector(".acoifilterpage-zvi-feedback").innerHTML = feedback_text;
}



// change the tags to an integer array
function getFiltersForRPC(filters)
{
  console.info("Filtervalue from form", filters);

  if ("thema" in filters)
    filters.thema = convertToIntegerArray(filters.thema);

  console.info("Filters for RPC", filters);

  return filters;
}


function convertToIntegerArray(filtervalue)
{
  let tags = [];
  if (Array.isArray(filtervalue))
  {
    for (let tag of filtervalue)
      tags.push(parseInt(tag));
  }
  else
    tags.push(parseInt(filtervalue));

  return tags;
}
