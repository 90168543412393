import "./financielewegwijzer.scss";
import "./financielewegwijzer_filters.scss";
import "./financielewegwijzer_item.css";
import "./instrument.scss";

import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-zvi/webdesigns/shared/components/filteredoverview";
import { onExpandFilters, onCollapseFilters } from "@mod-zvi/webdesigns/shared/components/filteredoverview/filter-dialog";
import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";


// NOTE: we must be sure to initialize after components are initialized
dompack.register("html.page--financielewegwijzer .acoifilterpage-filters", initFinancieleWegwijzer);

/*
dompack.onDomReady(initFinancieleWegwijzer);


function initFinancieleWegwijzer()
{
  console.log("initFinancieleWegwijzer", formnode);
  formnode = document.querySelector("html.page--financielewegwijzer .acoifilterpage-filters");
*/


function initFinancieleWegwijzer(formnode)
{
  console.log("initFinancieleWegwijzer", formnode);

  let filterform = formnode;
  let resultscontainer = document.querySelector(".acoifilterpage__results");

  let text = document.querySelector(".acoifilterpage").getAttribute("data-hiddenfilters");
  let hiddenfilters = JSON.parse(text);

  let settings =
          { trackingid:             "financielewegwijzer" // name for analytics (and debug)
          , statestorageid:         "financielewegwijzer" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".fwitem")

          , hiddenfilters:          hiddenfilters

          , method:                 "clientside"
          , fields_rpc:             ["query"]
          , fields_dontcountasactive: ["restricturl"] // prevent always calling the RPC just to pass "restricturl"
          , fields_showbadge:       ["trl", "sector", "financiering", "verstrekker", "fase", "tech"]
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , rpcsearchfunction:      "FindItemMatches" // generic search function
          , finalize_itemmatch:     isMatch
          , onafterrefresh:         onAfterRefresh

          , resultsperpage:         15

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters

          , debug:                  false
          , debug_rpc:              false
          };

  window.filteredoverview_fw = new FilteredOverview(formnode, settings);

  // always filter - also to activate pagination
  window.filteredoverview_fw.refreshResults();

  if (   ("fase" in window.filteredoverview_fw.filters)
      || ("tech" in window.filteredoverview_fw.filters)
     )
  {
    let button = document.querySelector(".filterpanel__showmorebutton");
    button.__toggleState(button );
    // window.filteredoverview_fw.showAdditionalFilters(true);
  }
/*  else
  {
    // since we are using the default filters we can use the publications statically published into the page
    window.filteredoverview_publications.setFeedback(whintegration.config.obj.publicationsoverview.resultscount);
    enrichPublicationDetailsPageLinks();
  }
*/
}


let count_mightmatch = 0;


// After an update of results mention how many matches were
// left out because we didn't know if they matched our filters.
// ("do to not having the information or it (for example max funding) depending on other factors - for example if max funding is very dependant on amount of participants")
function onAfterRefresh()
{
  console.info("items which we couldn't for sure match " + count_mightmatch);
  count_mightmatch = 0;
  // doUpdateCSVLink(); - will be replaced with an XLSX download option
}

function isMatch(itemdata, filters, itemnode)
{
  let filter_by_maxfunding = ("minbijdrage" in filters) || ("maxbijdrage" in filters);

  if (!filter_by_maxfunding)
  {
    // console.info("No filtering by maxcontribution");
    return true;
  }

  if (!itemdata.maxcontribution)
  {
    console.info("Item heeft geen maxcontribution waarde");
    count_mightmatch++;
    return false;
  }

  filters.minbijdrage = parseInt(filters.minbijdrage);
  filters.maxbijdrage = parseInt(filters.maxbijdrage);

  let match_minbijdrage = !filters.minbijdrage || (itemdata.maxcontribution >= filters.minbijdrage);
  let match_maxbijdrage = !filters.maxbijdrage || (itemdata.maxcontribution <= filters.maxbijdrage);

  console.info( "filters.minamount",        filters.minbijdrage, "\n"
              , "filters.maxbijdrage",      filters.maxbijdrage, "\n"
              , "itemdata.maxcontribution", itemdata.maxcontribution, "\n"
              , "isMatch minbijdrage",        match_minbijdrage, "\n"
              , "isMatch maxbijdrage",      match_maxbijdrage, "\n"
              , "node", itemnode
              );

  return (match_minbijdrage && match_maxbijdrage);
}



function doUpdateCSVLink()
{
  let rows = [];
  rows.push(whintegration.config.obj.fo_csvheaders);

  console.info(`There are ${window.filteredoverview_fw.items.length} items.`);
  for(let item of window.filteredoverview_fw.items)
  {
    console.log(item.ismatch, item);
    if (item.ismatch)
      rows.push(item.filterdata.csvrow);
  }


  console.group("Export");
  console.log(rows);
  console.groupEnd();


  let csvContent = "";
  rows.forEach(row => {
    csvContent += row.join('\t') + '\n'
  })


  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
  const objUrl = URL.createObjectURL(blob)

  const link = document.body.querySelector("a.downloadresultsbutton");
  link.setAttribute('href', objUrl)
  link.setAttribute('download', 'financielewegwijzer-export.csv')
}
