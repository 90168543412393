import * as dompack from "dompack";
import "./accordion.css";


window.addEventListener("change", evt => closeOtherAccordionItems(evt));

function closeOtherAccordionItems(evt)
{
  // Was an accordion item being toggled?
  if (!evt.target.classList.contains(".widget-accordion__toggler"))
    return;

  // Prevent the user from accidentally triggering a selection
  evt.preventDefault();

  let accordion = dompack.closest(evt.target, ".widget-accordion");
  //if (!accordion)
  //  return;

  let checknodes = accordion.querySelectorAll(".widget-accordion__toggler")
  for(let node of checknodes)
  {
    if (node != evt.target)
      node.checked = false;
  }
}
