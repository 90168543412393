import "./pagehighlights.scss";

import * as CarrouselContent from "../../components/carrouselcontent";
import * as dompack from "dompack";


dompack.register(".widget-pagehighlights", initPageHighlightsWidget);


function initPageHighlightsWidget(widgetnode)
{
  new CarrouselContent.CarrouselContent(widgetnode
            , { viewport:            ".widget-pagehighlights__itemswrapper"
              , viewport_inner:      ".widget-pagehighlights__items"
              , itemsselector:       ".widget-pagehighlights__items > *" // measure the child because .widget-event__block may be display: contents;
              , bulletscontainer:    ".widget-pagehighlights__bullets"
              , bulletclass:         "carrouselbullet"
              , bulletselectedclass: "carrouselbullet--selected"

              , previousbutton:      widgetnode.querySelector(".widget-pagehighlights__previousbutton")
              , nextbutton:          widgetnode.querySelector(".widget-pagehighlights__nextbutton")

              , debug:  false
              , __test: "pagehighlightswidget"

              });
}
