import * as dompack from "dompack";
import * as CarrouselContent from "@mod-zvi/webdesigns/shared/components/carrouselcontent";


dompack.register(".widget-persons--withpopups", initPersonsPopups);

function initPersonsPopups()
{
  window.addEventListener("click", doCheckForPersonItemClick);
}

function doCheckForPersonItemClick(evt)
{
  let personitem = evt.target.closest(".widget-persons__item");
  if (!personitem)
  {
/*
    let dialog = evt.target.closest(".personcard__closebutton");
    if (dialog)
      dialog.closeDialog();
*/
    return;
  }

  let dialogid = personitem.getAttribute("aria-controls");

  console.log(personitem, dialogid);

  let dialognode = document.getElementById(dialogid);

  if (!dialognode)
  {
    console.error("Failed to find dialog with id", dialogid);
    return;
  }

  dialognode.showModal();

  dialognode.addEventListener("scroll", evt => onScrollDialogContent(evt));
}

function onScrollDialogContent(evt)
{
  // console.info(evt.target.scrollTop);
  evt.target.classList.toggle("dialog--scrolled", evt.target.scrollTop > 0);
}




dompack.register(".widget-persons--carrouselonmobile", initPersonsCarrousel);


function initPersonsCarrousel(widgetnode)
{
  console.log("initPersonsCarrousel", widgetnode);

  let slideshow = new CarrouselContent.CarrouselContent(widgetnode
            , { viewport:            ".widget-persons__items"
              , viewport_inner:      ".widget-persons__items"
              , itemsselector:       ".widget-persons__item"

              , bulletscontainer:    ".widget-persons__jumpbuttons"
              , bulletclass:         "spc-jumpbutton"
              , bulletselectedclass: "spc-jumpbutton--selected"

              , __test: "persons"

              , previousbutton:      widgetnode.querySelector(".widget-persons__previous")
              , nextbutton:          widgetnode.querySelector(".widget-persons__next")
              });
  widgetnode.__slideshowinstance = slideshow;
}
