import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-zvi/webdesigns/shared/components/filteredoverview";
import { onExpandFilters, onCollapseFilters } from "@mod-zvi/webdesigns/shared/components/filteredoverview/filter-dialog";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";


dompack.register("html.page--search .acoifilterpage-filters", initSearchPage);


let filterform;
let resultscontainer;

function initSearchPage(formnode)
{
  filterform = formnode;
  resultscontainer = document.querySelector(".acoifilterpage__results");

  let text = document.querySelector(".acoifilterpage").getAttribute("data-hiddenfilters");
  let hiddenfilters = JSON.parse(text);

  let settings =
          { trackingid:             "searchpage" // name for analytics (and debug)
          // , statestorageid:         "searchpage" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".spc-tile")
       // , getfloatingteasertext:  getFloatingTeaserText

          , hiddenfilters:          hiddenfilters

          , method:                 "rpc"
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , rpcsearchfunction:      "GenericSearch"
          , getfiltersforrpc:       getFiltersForRPC

          //, ongotrpcresults:
          , onafterrefresh:         onAfternewResults

          , resultsperpage:         20

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters

          , debug:                  false
          , debug_rpc:              false
          };

  console.log("initSearchPage", settings);

  window.filteredoverview_searchpage = new FilteredOverview(formnode, settings);

// FIXME!! only if a textual search is active
  if (window.filteredoverview_searchpage.isFilterActive())
    window.filteredoverview_searchpage.refreshResults(); // do initial search


//onExpandFilters();

/*
  if (window.filteredoverview_publications.isFilterActive())
    startOverview();
  else
  {
    // since we are using the default filters we can use the publications statically published into the page
    window.filteredoverview_publications.setFeedback(whintegration.config.obj.publicationsoverview.resultscount);
    enrichPublicationDetailsPageLinks();
  }
*/
}

function onAfternewResults()
{
  let instance = window.filteredoverview_searchpage;

  /*
  console.log("onAfternewresults FILTERS", instance.filters);
  console.log("RPC response", instance.last_rpcresponse);;
  */

  let resultscount = instance.resultscount;
  let query = instance.filters.query        ?? "";
  let thema_use = "thema" in instance.filters;
  let thema_title = instance.filters_titles.thema ?? "";

  let pagetype = "soort" in instance.filters ? instance.filters_titles.soort : "";

  // FIXME: geef een veld door in instance.last_rpcresponse voor aantal resultaten zonder te filteren op pagina type??

  let text_amount = "";
  let text_describe_query = "";
  let text_pagetype = "";
  let feedback_text = ""; // Final text

  if (resultscount > 1)
    text_amount = `Er zijn <strong>${resultscount} resultaten</strong>`;
  else if (resultscount == 1)
    text_amount = `Er is één resultaat`;
  else
    text_amount = `Er zijn geen resultaten`;

  if (query != "" && thema_use)
    text_describe_query = `voor "${query}" en thema "${thema_title}"`;
  else if (query != "")
    text_describe_query = `voor "${query}"`;
  // NOTE: we always require an query...


  if (pagetype != "")
    text_pagetype = `in "${pagetype}"`;

  if (text_describe_query != "" && text_pagetype != "")
    feedback_text = `${text_amount} ${text_describe_query} ${text_pagetype}.`;
  else
    feedback_text = `${text_amount} ${text_describe_query}${text_pagetype}.`;

  document.querySelector(".acoifilterpage-zvi-feedback").innerHTML = feedback_text;
  // console.log(feedback_text);
}



// change the tags to an integer array
function getFiltersForRPC(filters)
{
  console.info("Filtervalue from form", filters);
/*
  if ("tags" in filters)
    filters.tags = convertToIntegerArray(filters.tags);
*/
  if ("thema" in filters)
    filters.thema = convertToIntegerArray(filters.thema);

  console.info("Filters for RPC", filters);

  return filters;
}


function convertToIntegerArray(filtervalue)
{
  let tags = [];
  if (Array.isArray(filtervalue))
  {
    for (let tag of filtervalue)
      tags.push(parseInt(tag));
  }
  else
    tags.push(parseInt(filtervalue));

  return tags;
}
