/*

Generic handler for the cookiebar and privacy settings widget for:
- https://www.financieelfittewerknemers.nl/ (mijnwerknemerengeldzaken webdesign)
- https://www.weekvanhetgeld.nl/            (weekvanhetgeld webdesign)
- https://www.wijzeringeldzaken.nl/         (wijzeringeldzaken webdesign)

- https://www.4tu.nl

- ACOI

*/

import * as consenthandler from "@mod-publisher/js/analytics/consenthandler";
import * as dompack from "dompack";

import "./consentbar/consentbar.scss";
import "./consentlayers.scss";

import "./consent.lang.json";
import getTid from "@mod-tollium/js/gettid";
import * as whintegration from "@mod-system/js/wh/integration";
import '@mod-publisher/js/analytics/gtm';

/*
For consenthandler documentation see:
https://gitlab.webhare.com/webhare/webhare/blob/master/doc/code.webhare.com/publisher/consenthandler.md
*/

window.consenthandler = consenthandler;



let consentbar_initialized = false;

let settingslink;

if(!whintegration.config.obj.consentsettingslink)
  console.warn("Missing consentsettingslink in whintegration.config.obj (webdesign->jsobjconfig).");
else
{
  // Add the current URL as variable so we can return here if the link was clicked
  settingslink = whintegration.config.obj.consentsettingslink + "?privacyback=" + encodeURIComponent(location.pathname);
}


export function initialize(cookiename)
{
  consenthandler.setup( cookiename
                      , showConsentBar
                      , { defaultconsent: ["analytics"]
                        });

  window,addEventListener("click", doCheckForConsentClick);

  dompack.register(".embeddedobject--privacysettings", initPrivacySettingsWidget);
}

function doCheckForConsentClick(evt)
{
  let button = evt.target.closest(".consentblocker__acceptcookies");
  if (!button)
    return;

  console.info("Accept clicked!");
  onConsentButtonClicked();
}



//////////////////////////////////////////////////////////////////////////////
//
//  Consent bar
//

function showConsentBar(evt)
{
console.log(evt);
return; // most users probably won't interact with anything needing 3th party cookies on this site, so don't use the consentbar

  let consentbar = document.querySelector(".consentbar");
  if(!consentbar)
    return;

  // When the privacy widget is on the page, don't show the consentbar
  if(   !document.documentElement.classList.contains("hasconsentsettings")
     && !document.body.querySelector(".embeddedobject--privacysettings"))
    consentbar.classList.add("consentbar--visible");

  if (consentbar_initialized)
    return;

  consentbar_initialized = true;

  document.querySelector(".consentbar__acceptcookiesbutton").addEventListener("click", onConsentButtonClicked);

  window.addEventListener("wh:showconsentbar", showConsentBar);
}

function onConsentButtonClicked()
{
  let consentbar = document.querySelector(".consentbar");
  if (consentbar)
    consentbar.classList.remove("consentbar--visible");

  consenthandler.setConsent(["analytics", "all"]);
}

function updateConsentInterface(state)
{
  console.log("updateConsentInterface", state);

  let psform = document.querySelector(".embeddedobject--privacysettings form");
  for (let node of Array.from(psform.consent))
    node.checked = state.consent.indexOf(node.value) > -1;
}



//////////////////////////////////////////////////////////////////////////////
//
//  Consent settings widget
//

function initPrivacySettingsWidget()
{
  let psform = document.querySelector(".embeddedobject--privacysettings form");
  psform.addEventListener("submit", onSubmitPrivacySettings);
  //psform.addEventListener("change", onChangePrivacySettings);

  // consenthandler.onConsentChange(evt => updateConsentInterface(evt));
  consenthandler.onConsentChange(updateConsentInterface);
}


function readPrivacySettingsFromForm(form)
{
  console.log("Form", form);

  let consentto = [];

  for (let checkbox of Array.from(form.consent))
  {
    if (checkbox.checked)
      consentto.push(checkbox.value);
  }

  return consentto;
}

/*
function onChangePrivacySettings(evt)
{
  evt.preventDefault();
  let consentto = readPrivacySettingsFromForm(evt.target.form);
  consenthandler.setConsent(consentto);
}
*/

function onSubmitPrivacySettings(evt)
{
  evt.preventDefault();
  let consentto = readPrivacySettingsFromForm(evt.target);

console.info("Update consent to", consentto);

  consenthandler.setConsent(consentto);
  document.querySelector(".embeddedobject--privacysettings").classList.add("submitted");

  let backurl = new URL(location.href).searchParams.get("privacyback");
  if(backurl && backurl[0]=='/') //prevent cross domain redirect
    location.href = backurl;
}


////////////////////////////
//
// 'modern' consent layer
dompack.register(".wh-requireconsent__overlay", node =>
{
  console.log("consentoverlay");
  if (!("consentcookie" in whintegration.config.obj))
  {
      console.log("no consent needed");
    return;
  }

  let title;
  let message;

  let videonode = dompack.closest(node,'.wh-video') || dompack.closest(node,'.video') || dompack.closest(node, ".widget-spcvideo");
  if(videonode)
  {

  console.log("Consentlayer node", node);
  console.log("videonode", videonode);
  console.log("data-wh-video", videonode.getAttribute("data-wh-video"));

    let video = JSON.parse(videonode.getAttribute("data-wh-video"));
    if (video.network == "youtube")
    {
      title = getTid("zvi:webdesigns.shared.consent.consentblock-video-youtube-title");
      message = getTid("zvi:webdesigns.shared.consent.consentblock-video-youtube-message");
    }
    else if (video.network == "vimeo")
    {
      title = getTid("zvi:webdesigns.shared.consent.consentblock-video-vimeo-title");
      message = getTid("zvi:webdesigns.shared.consent.consentblock-video-vimeo-message");
    }
  }
  else
    message = getTid("zvi:webdesigns.shared.consent.consentblock-fallback");

  node.appendChild(<div class="consentblocker">
                     <div class="consentblocker__title">{title}</div>
                     <span>
                       { message }<br />
                       <button class="wh-form__button wh-form__button--solid consentblocker__acceptcookies"
                               aria-label={ getTid("zvi:webdesigns.shared.consent.consentblock-acceptbutton-arialabel") }
                               >{ getTid("zvi:webdesigns.shared.consent.consentblock-acceptbutton-label") }</button>
                     </span>
                   </div>);

  // Or use this if we want to send the user to the consent settings page:
  //    <span>{ text }<br /><a class="wh-form__button wh-form__button--solid wh-form__button--yellow" href={settingslink}>{ getTid("zvi:webdesigns.shared.consent.consentblock-link") }</a></span>
});

//pass on consent changes to GTM as discussed
consenthandler.onConsentChange(consentsettings =>
{
  let consentsetting = consentsettings.consent.length ? consentsettings.consent.join(' ') : "denied";
  window.dataLayer.push({"wh.consent":consentsetting, "event":"wh-consentchange"});
});
