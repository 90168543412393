import * as dompack from "dompack";
import { getTid } from "@mod-tollium/js/gettid";

import "@mod-zvi/webdesigns/site/site.lang.json";


/*
- using event delegation to keep the amount of eventlisteners down
  and prevent breakage due to DOM rewriting (maybe helps tools such as readSpeaker ?)
*/

let paginationbarnodes = [];

let observer = new ResizeObserver(relayoutPaginationBars);

function relayoutPaginationBars(entries)
{
  for (const entry of entries)
  {
    createPaginationBar( entry.target.parentNode
                       , entry.target.__pagination
                       , entry.target.__paginationoptions
                       , true // relayout only, so it can skip rerendering if there's no change in amount of buttons to show
                       );
  }
}



// Generate a pagination bar within the inserted page
// (using up empty space for page numbers, so the amount depends on how much width we get to work with)
export function createPaginationBar(insertinto, pagination, options, relayout)
{
  /*
  console.group("createPaginationBar");
  //console.log(insertinto, pagination, options, relayout);
  console.log("Pagination", pagination);
  console.log("Options", options);
  console.trace();
  console.groupEnd();
  */
  let totalcount = pagination.itemCount;
  let resultsPerPage = pagination.itemsPerPage;
  let currentPage = pagination.currentPage;

  if (!options.gotoPageCallback)
  {
    console.error("missing options.gotoPageCallback");
    return;
  }

  if (!options.controls)
  {
    console.error("missing options.controls (ID of the container element which has the paginated content)");
    return;
  }



  let container = insertinto.querySelector(".spc-pagination");

  const lastPage = totalcount > 0 ? Math.ceil(totalcount / resultsPerPage) - 1 : 0;

  // If there's only one page we don't need to create/update
  // the page navigation.
  if (lastPage == 0)
  {
    if (container)
    {
      // Store the current state
      container.__pagination = pagination;
      container.setAttribute("data-currentpage", currentPage);
      container.setAttribute("hidden", "");
    }
    return;
  }



  // Ensure the pagination DOM exists
  if (!container)
  {
    let paginationobj = makePaginationContainer(options);
    container = paginationobj.container;
    insertinto.appendChild(container);

    // console.log("Adding to observer:", container);
    observer.observe(container);
    paginationbarnodes.push(container);
  }

  // Store the current state
  container.__pagination = pagination;
  container.setAttribute("data-currentpage", currentPage);
  container.removeAttribute("hidden");


  let pagescontainer = insertinto.querySelector(".spc-pagination__pages");


  container.toggleAttribute("hidden", lastPage == 0);

  container.querySelector(".spc-pagination__previous").toggleAttribute("disabled", currentPage == 0);
  container.querySelector(".spc-pagination__next").toggleAttribute("disabled", currentPage >= lastPage);


  let computed = getComputedStyle(pagescontainer);

  let width = pagescontainer.clientWidth;
  let spacing = parseInt(computed.getPropertyValue("--pagination-page-spacing"));
  let itemwidth = parseInt(computed.getPropertyValue("--pagination-page-size"));

  let maxbuttons = Math.floor((width + spacing) / (itemwidth + spacing));
  let extend_left = Math.ceil((maxbuttons - 1) / 2); // subtract one because for the currentpage is in the middle, round up to favor previous pages

  let page_start = Math.max(0, currentPage - extend_left);

  let page_end = page_start + maxbuttons - 1;
  if (page_end > lastPage) // if we are nearing the end, don't center around the currentpage but fill up with page numbers at the end
  {
    page_end = lastPage;
    page_start = Math.max(lastPage - maxbuttons + 1, 0);
  }

  /*
  console.log(
        { currentPage: currentPage
        , totalcount:  totalcount
        , resultsPerPage: resultsPerPage
        });
  */

  // Determine whether we actually need to add/remove buttons ///////////////////////////////
  let amount_of_buttons = page_end - page_start + 1;

  if (relayout && (amount_of_buttons == container.__layout_pagebuttonscount))
  {
    //console.info("Skip - no change in buttoncount");
    return;
  }

  //if (relayout)
  //  console.info("Refreshing buttons");

  container.__layout_pagebuttonscount = amount_of_buttons;
  ////////////////////////////////////////////////////////////////////////////////////////////


  // /*
  // Code for debugging
  // and rendering analysis

  let space_usage = maxbuttons * itemwidth + (maxbuttons - 1) * spacing;
  let space_left = width - space_usage;

  /*
  console.log({ pagesbar_width: width
              , spacing:     spacing
              , itemwidth:   itemwidth
              , maxbuttons:  maxbuttons
              , extend_left: extend_left
              , page_start:  page_start
              , page_end:    page_end
              , lastPage:    lastPage
              , space_usage:  space_usage
              , space_left:   space_left
              });
  */

  pagescontainer.innerHTML = "";

  for (let page = page_start; page <= page_end; ++page)
  {
    if (page == currentPage)
    {
      pagescontainer.append(
        <span class="spc-pagination__page current"
              aria-current="page"
              aria-label={"Pagina "+(page+1)}
              >{page + 1}</span>);
    }
    else if (page == page_start && page > 0)
      pagescontainer.append(<span class="spc-pagination__ellipsis"></span>);
    else if (page == page_end && page < lastPage - 1) // our last button isn't the last page
      pagescontainer.append(<span class="spc-pagination__ellipsis"></span>);
    else
    {
      pagescontainer.append(
        <button class="spc-pagination__page"
                aria-label={"Pagina "+(page+1)}
                data-setpage={page}
                >{page + 1}</button>);
    }
  }

  return container;
}


function makePaginationContainer(options)
{
    // FIXME: if the results container doesn't have an id we need to generate an ID ?
  let controls_id = options.controls.getAttribute("id");

  const container =
      <nav class="spc-pagination"
           aria-controls={controls_id}
           aria-label="Paginatie"
           ></nav>;

  container.__paginationoptions = options;
  container.addEventListener("click", evt => checkPaginationClick(evt));

  // Add a 'previous' link
  let prevbutton =
      <button class="spc-pagination__previous"
              aria-label={getTid("zvi:webdesigns.site.js.pagination.previouspage-arialabel")}
              >
    {/*{getTid("zvi:webdesigns.site.js.pagination.previouspage")}*/}
     </button>;

  // Add a 'next' link
  let nextbutton =
      <button class="spc-pagination__next"
              aria-label={getTid("zvi:webdesigns.site.js.pagination.nextpage-arialabel")}
              >
            {/*{getTid("zvi:webdesigns.site.js.pagination.nextpage")}*/}
      </button>

  const pagescontainer = <div class="spc-pagination__pages"></div>;

  container.append(prevbutton);
  container.appendChild(pagescontainer);
  container.append(nextbutton);

  return { container: container };
}


function checkPaginationClick(evt)
{
  let pagination = evt.target.closest(".spc-pagination");

  let prev = evt.target.closest(".spc-pagination__previous");
  if (prev)
  {
    let currentpage = parseInt(pagination.dataset.currentpage);
    if (currentpage > 0)
      pagination.__paginationoptions.gotoPageCallback(currentpage - 1);

    event.preventDefault();
    return;
  }

  let next = evt.target.closest(".spc-pagination__next");
  if (next)
  {
    let currentpage = parseInt(pagination.dataset.currentpage);
    pagination.__paginationoptions.gotoPageCallback(currentpage + 1);
    event.preventDefault();
    return;
  }

  let setpagebutton = evt.target.closest("[data-setpage]");
  if (setpagebutton)
  {
    let pagenr = parseInt(setpagebutton.dataset.setpage);
    pagination.__paginationoptions.gotoPageCallback(pagenr);
    event.preventDefault();
    return;
  }
}
