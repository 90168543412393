import * as dompack from "dompack";


let filterdialog = null;
let synced_fields = [];


/* @short open mobile fullscreen filters
   @description If .acoifilterpage-filterdialog-content exists it'll be used as filter content.
                Otherwise the normal filters (.acoifilterpage-filters__filters) will be moved into the dialog,
*/
export function onExpandFilters(filteredoverview)
{
  console.info("onExpandFilters");

  // move the content into this new <dialog>
  let templatenode = document.querySelector(".acoifilterpage-filterdialog-content");

  let dialogcontent = null;
  let filters_moved_into_dialog = false;
  let filterspanel = null;

  if (templatenode)
    dialogcontent = templatenode.content.cloneNode(true);
  else
  {
    filterspanel = document.querySelector(".acoifilterpage-filters__filters");
    filterspanel.__after = filterspanel.nextSibling;
    dialogcontent = filterspanel;

    filters_moved_into_dialog = true;
  }



  // Use a cloned dialogtemplate (which includes the closebutton)
  // and move the content .acoifilterpage-filterdialog into it.
  let dialogfrag = document.getElementById("dialogtemplate").content.cloneNode(true);
  let dialog = dialogfrag.querySelector("dialog");
  dialog.__temporary_imported_nodes = [];
  let dialog_contentnode = dialogfrag.querySelector(".dialog__content");
  dialog.classList.add("filtersdialog");

  dialog_contentnode.appendChild(dialogcontent);


  // Keep information so we can find this dialog is associated with our filteredoverview.
  // This way we the filteredoverview can update the teasertext.
  // (based on the [data-filtersdialog-for] .filteredoverview-teasertext)
  let overviewid = filteredoverview.getOverviewID();
  dialog.setAttribute("data-filtersdialog-for", overviewid);


  if (!filters_moved_into_dialog)
  {
    // We create a copy of the mobile filters, so we need to initialize these
    dompack.registerMissed(dialog_contentnode);
    document.body.appendChild(dialog);
  }
  else
  {
    dialog.__temporary_imported_nodes.push(filterspanel);
    filterspanel.__after.parentNode.insertBefore(dialog, filterspanel.__after);
  }


  let dialog_footer = document.createElement("div");
  dialog_footer.className = "site-dialog__footer site-dialog__footer-sticky";

  let dialog_footer_content = document.createElement("div");
  dialog_footer_content.className = "site-dialog__footer__content";

  let applybutton = document.createElement("button");
  applybutton.setAttribute("type", "button");
  applybutton.className = "filtersdialog__applybutton filteredoverview-teasertext wh-form__button wh-form__button--solid filteredoverview__toggleaction";
  applybutton.innerText = filteredoverview.getTeaserText();
  applybutton.setAttribute("aria-label", "Apply filters and close dialog");

  dialog_footer.appendChild(applybutton);
  dialog_contentnode.appendChild(dialog_footer);


  // Setup syncing values between the dialog and normal filters
  let dialog_form = dialog.querySelector("form");


  // If we have a copy of all filters in the dialog (not reusing/moving the normal filters into the <dialog>)
  if (!filters_moved_into_dialog)
  {
    /* We render a mobile specific form because on mobile we want a different
       (more compact) view of the filters. This might be using pulldowns instead
       of radiobuttons. Since these requires a different DOM setup we have a mobile
       specific form which we in the background will sync to the form the FilteredOverview uses. */
    ensureFormSyncsWithFilteredOverview(dialog_form, filteredoverview);
  }


  if (dialog.showModal)
    dialog.showModal();
  else
    dialog.setAttribute("open", ""); // fallback for browsers without <dialog> support

  filterdialog = dialog;
}


function ensureFormSyncsWithFilteredOverview(formnode, filteredoverview)
{
  console.log("ensureFormSyncsWithFilteredOverview", formnode, filteredoverview);

  synced_fields = formnode.querySelectorAll("input[name], select[name]");

  // Copy the currently used filters to our form
  filteredoverview.prefillFormWithFilterValues(synced_fields, filteredoverview.filters);

  // Ensure submit and reset actions are synced to the FilteredOverview form too
  formnode.addEventListener("submit", evt => { filteredoverview.onSubmit(evt); });
  formnode.addEventListener("reset", evt => filteredoverview.doClearFilters(evt));

  for(let node of synced_fields)
    node.addEventListener("change", ev => syncFormToFilteredoverview(ev, filteredoverview));
}


function syncFormToFilteredoverview(evt, filteredoverview)
{
  // Retrieve the mobile form filters in the filter record format the FilteredOverview uses.
  // Include empty values so we also sync "don't filter based on this criteria" values.
  let filters = filteredoverview.getFiltersFromNodes(synced_fields, true);

  console.info("syncing to FO:", filters);

  filteredoverview.setFilters(filters.filters);
}


export function onCollapseFilters()
{
  // Return filters to original location
  if (filterdialog.__temporary_imported_nodes.length > 0)
  {
    for(let node of filterdialog.__temporary_imported_nodes)
    {
      // Restore node to original location
      node.__after.parentNode.insertBefore(node, node.__after);
      delete node.__after;
    }
  }

  if (filterdialog.showModal)
    filterdialog.close();
  else
    filterdialog.removeAttribute("open");

  filterdialog.remove();

  filterdialog = null;
}
