import videojs from "video.js";

export default class ToggleCaption extends videojs.getComponent('Button')
{
  constructor(player, options = {}, buttonOptions = {}, node)
  {
    super(player, options);
    this.node = node;
    this.player = player;
    this.button = this.el_;
    this.type = buttonOptions.type; // 'subtitles', 'captions'
    this.addClass('vjs-captions-toggle');
    this.addClass(buttonOptions.className);

    /*
    NOTES:
    - .vjs-icon-placeholder has aria-hidden="true" so text placed there WON'T
      be picked up by the screenreader!
    - .vjs-control-text has aria-live="polite", so changes will be read aloud by the screenreader
    */
    this.el_.setAttribute("title", buttonOptions.title); // to show the purpose of the button when hovering over it
    this.el_.querySelector('.vjs-icon-placeholder').textContent = buttonOptions.iconText; // show abbreviation to show on the button
    this.el_.querySelector('.vjs-control-text').textContent = buttonOptions.title; // visually hidden label meant for screenreaders
  }

  handleClick(_e)
  {
    const clickedTrackIsActive = this.player.textTracks().tracks_.find(el => el.kind == this.type && el.mode == 'showing');

    // loop through tracks to disable or enable them
    for (const track of this.player.textTracks().tracks_)
    {
      track.mode = 'hidden';

      if (!clickedTrackIsActive && track.kind == this.type) // requested track wasn't showing yet
        track.mode = 'showing';
    }

    // remove all 'active' classes
    for (const button of this.node.querySelectorAll('.vjs-captions-toggle'))
      button.classList.remove('vjs-on');

    // and enable the correct one
    if (!clickedTrackIsActive)
      this.button.classList.add('vjs-on');
  }
}
