import * as dompack from "dompack";


window.__dompack_spcslideshow_idx = 0;
window.__dompack_spcslideshows = [];




export default class SlideShow
{
  constructor(node, options)
  {
    if (!node)
    {
      console.error("spcSlideshow got null as node.");
      return;
    }

    this.options = Object.assign(
          { autoplay:                     true
          , autoplay_initialdelay:           0 // delay the first start of autoplay to prevent having a lot of slideshows run in sync
          , autoplay_freezeslideduration: 6000 // how long to keep the slide still (FIXME: should be after the transition) before going to the next

          , buttonprevious:        null
          , buttonnext:            null
          , buttonplaybacktoggle:  null

          , slides:                ".carrousel__cell"

          , debug:                 false
          , name:                  "slideshow" + window.__dompack_cslideshow_idx

          , activeclass:           "activeslide"

          , jumpbuttons:              null
          , jumpbutton_selectedclass: "active"
          }, options);

    if (this.options.debug)
      console.log("Slideshow options", this.options);



    window.__dompack_spcslideshow_idx++; // used for anonymous this.option.name
    window.__dompack_spcslideshows.push(this);

    this.node = node;

    this.slides = [];

    this.slideidx = 0;
    this.slidenodes = []; // current slide node and nodes linked to this slide

    this.slideprevidx = -1;


    // autoplay support variables
    this.autoplaytimer = null;
    this.lastautoslidetime = null;
    this.playing = this.options.autoplay;


    if (typeof this.options.slides == "string")
      this.slides = node.querySelectorAll(this.options.slides);
    else
      this.slides = this.options.slides;


    this.jumpbuttonnodes = [];
    if (this.options.jumpbuttons)
    {
      if (typeof this.options.jumpbuttons == "string" && this.options.jumpbuttons != "")
        this.jumpbuttonnodes = this.node.querySelectorAll(this.options.jumpbuttons);
      else
        this.jumpbuttonnodes = this.options.jumpbuttons; // assume an array, live nodelist or static nodelist


      let lang = document.documentElement.getAttribute("lang");

      for (let idx = 0; idx < this.jumpbuttonnodes.length; idx++)
      {
        let button = this.jumpbuttonnodes[idx];
        button.addEventListener("click", evt => this.setSlide(idx));

        // Have a fallback aria-label for accessicity if it's not currently available in the HTML
        // FIXME: language specific text
        if (!button.hasAttribute("aria-label"))
        {
          if (lang == "nl")
            button.setAttribute("aria-label", "Bekijk slide " + (idx+1));
          else
            button.setAttribute("aria-label", "View slide " + (idx+1));
        }
      }
    }


    if (this.options.buttonprevious)
      this.options.buttonprevious.addEventListener("click", this.__handlePrevButton.bind(this));

    if (this.options.buttonnext)
      this.options.buttonnext.addEventListener("click", this.__handleNextButton.bind(this));

    if (this.options.buttonplaybacktoggle)
    {
      this.options.buttonplaybacktoggle.setAttribute("role", "switch");
      this.options.buttonplaybacktoggle.setAttribute("aria-checked", "");
      // this.options.buttonplaybacktoggle.setAttribute("aria-label", "Afspelen");
      this.options.buttonplaybacktoggle.addEventListener("click", this.__handleTogglePlaybackButton.bind(this));
    }


    this.setSlide(0);

    if (this.playing)
      this.__applyCurrentAutoplay(this.options.autoplay_initialdelay);
  }

  destroy()
  {
    clearTimeout(this.autoplaytimer);
    this.autoplaytimer = null;
    this.playing = false;
  }

  __handlePrevButton()
  {
    this.previousSlide();
  }

  __handleNextButton()
  {
    this.nextSlide();
  }

  __handleTogglePlaybackButton()
  {
    console.log("**");
    this.togglePlayback();
  }


  togglePlayback()
  {
    this.playing = !this.playing;
    this.__applyCurrentAutoplay();
  }

  pause()
  {
    this.playing = false;
    this.__applyCurrentAutoplay();
  }

  play()
  {
    this.playing = true;
    this.__applyCurrentAutoplay();
  }



  setSlide(idx)
  {
    // remove all active classes/attributes from elements related to the current slide
    for (let node of this.slidenodes)
    {
      node.classList.remove(this.options.activeclass);
      node.removeAttribute("aria-current");
    }


    let newslidenode = this.slides[idx];
    let linkednodes = [];

    let relatednodes = newslidenode.getAttribute("data-slideshow-elements");
    if (relatednodes && relatednodes != "")
    {
      for (let nodeid of relatednodes.split(" "))
      {
        let node = document.getElementById(nodeid);
        if (!node)
        {
          console.error("Cannot find node with id", nodeid);
          continue;
        }
        linkednodes.push(node);
      }
    }

    this.slidenodes = [ newslidenode, ...linkednodes ];

    // Add the activateclass to the slide and all nodes marked as relating to this slide
    for (let node of this.slidenodes)
    {
      node.classList.add(this.options.activeclass);
      node.setAttribute("aria-current", "step");
    }

    this.slideprevidx = this.slideidx;
    this.slideidx = idx;



    let count = this.jumpbuttonnodes.length;

    if (count > this.slideprevidx)
      this.jumpbuttonnodes[this.slideprevidx].classList.remove(this.options.jumpbutton_selectedclass);

    if (count > this.slideidx)
      this.jumpbuttonnodes[this.slideidx].classList.add(this.options.jumpbutton_selectedclass);

  }

  previousSlide()
  {
    let nextslide = this.slideidx - 1;
    if (nextslide < 0)
      nextslide = this.slides.length - 1;

    this.setSlide(nextslide);
  }

  nextSlide()
  {
    let nextslide = this.slideidx + 1;
    if (nextslide > this.slides.length-1)
      nextslide = 0;

    this.setSlide(nextslide);
  }



  __applyCurrentAutoplay(delay)
  {
    if (this.options.debug)
      console.log("__applyCurrentAutoplay");

    if (!delay)
      delay = 0;

    this.node.classList.toggle("spc-slideshow--playing", this.playing);

    //console.log(this.__nextSlideByTimer);
    if (this.playing)// && !document.hidden && this.inviewport)
    {
      if (this.options.buttonplaybacktoggle)
        this.options.buttonplaybacktoggle.setAttribute("aria-checked", "");

      if (!this.autoplaytimer)
      {
        if (this.options.debug)
          console.log("Setting new timer for auto slide");

        this.autoplaytimer = setTimeout(this.__nextSlideByTimer.bind(this), this.options.autoplay_freezeslideduration + delay);
      }
      else if (this.options.debug)
        console.log("Timer already running...");
    }
    else
    {
      if (this.options.buttonplaybacktoggle)
        this.options.buttonplaybacktoggle.removeAttribute("aria-checked");

      if (this.options.debug)
        console.log("No autoplay");//, { play: this.playing, dochidden: document.hidden, inviewport: this.inviewport });

      if (this.autoplaytimer)
      {
        clearTimeout(this.autoplaytimer);
        this.autoplaytimer = null;
      }
    }
  }

  __nextSlideByTimer()
  {
    if (!this.options.autoplay) // did a timer event still get through?
      return;

    this.lastautoslidetime = new Date().getTime();

    this.nextSlide();

    this.autoplaytimer = setTimeout(this.__nextSlideByTimer.bind(this), this.options.autoplay_freezeslideduration);
  }
};
