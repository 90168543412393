import "./pagegrid-filter-dialog.scss";
import "./pagegrid-filterpage.scss";
import "./pagegrid-filters.scss";
import "./searchbar.css";

import * as dompack from "dompack";


dompack.register(".filterpanel__showmorebutton", doInitToggle);


function doInitToggle(node)
{
  console.info("init", node);
  node.addEventListener("click", evt => doToggleByEvent(evt));
  node.__toggleState = doToggle;
}

function doToggleByEvent(evt)
{
  let button = evt.target.closest(".filterpanel__showmorebutton");
  doToggle(button);
}

function doToggle(button)
{
  let new_visibility = !(button.getAttribute("aria-expanded") == "true");
  console.log("toggle - new new_visibility", new_visibility);

  button.setAttribute("aria-expanded", new_visibility ? "true" : "false");

  let panelid = button.getAttribute("aria-controls");
  let panel = document.getElementById(panelid);
  panel.toggleAttribute("hidden", !new_visibility);

  if (new_visibility)
    button.setAttribute("hidden", "");
}
