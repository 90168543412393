import "./video.scss";
import "./video-publisher.scss";

import * as dompack from "dompack";
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';


import * as videotools  from "../../../shared/wh.media.embedvideo.js";


window.addEventListener("click", evt => doCheckForConsentClick(evt));
window.addEventListener("click", doCheckForVideoTrigger);


function doCheckForConsentClick(evt)
{
  if (!dompack.closest(evt.target, ".spcvideo__requireconsent__accept"))
    return;

  console.log("setting consent");

  consenthandler.setConsent(["all"]);

  // let info = consenthandler.getConsentDetails();
  console.info(document.documentElement.dataset.whConsent);
}


function doCheckForVideoTrigger(event)
{
  /*
  var sps = dompack.closest(event.target, ".page-header__slideshow");
  if (sps)
    return; // we handle the pageheader slideshow somewhere else
  */

  //console.log(event);

  //var playbutton = dompack.closest(event.target, ".widget-video__playbutton");
  var playbutton = dompack.closest(event.target, ".wh-video__playbutton");
  if (!playbutton)
    return;

  //var videocontainer = playbutton.parentNode.querySelector(".videocontainer");
  var videowidget = dompack.closest(playbutton, ".widget-spcvideo");
  if (!videowidget) // probably a play button within a normal Publisher video widget
    return;

  var videocontainer = videowidget.querySelector("[data-video]");
  if (videocontainer)
  {
    videotools.initializeVideoElement(videocontainer);
    videowidget.classList.add("widget-video--active");
  }
}
