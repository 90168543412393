import * as dompack from "dompack";
import SlideShow from "../../../shared/components/spc-slideshow";

import "./ctacarrousel-jumpbuttons.css";
import "./ctacarrousel.css";


dompack.register(".widget-ctacarrousel", initFeaturedWidget);


function initFeaturedWidget(node)
{
  let slides = node.querySelectorAll(".widget-ctacarrousel__slide");

  // console.info("[ctacarrousel] ", slides.length, "slides");

  if (slides.length < 2)
    return;

  let options = { autoplay:       true
                , slides:         node.querySelectorAll(".widget-ctacarrousel__slide")
                , jumpbuttons:    node.querySelectorAll(".widget-ctacarrousel__tab")
                , autoplay_freezeslideduration: 6000 // how long to keep the slide still (FIXME: should be after the transition) before going to the next
              //, transitionDuration:    1500
                , buttonplaybacktoggle: node.querySelector(".widget-ctacarrousel__playbacktogglebutton")
                };

  // console.info("[Featured] initializing slideshow", options);

  let slideshow = new SlideShow(node, options);

  node.classList.add("widget-ctacarrousel--slideshow")
}
