/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

/* See https://code.webhare.com/publisher/utilities/linkhandler/
*/

/*
Code based on the Publisher linkhandler:
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
*/

import * as dompack from "dompack";

let linkopenoptions = null;


function linkIsExternal(link) {
  if (!link || link.download)
    return false;

  if (!['http', 'https'].includes(link.href.split(':')[0]))
    return false; //not a browser protocol, skip

  if (link.target) //never overwrite an explicit target
    return false;

  const destdomain = (new URL(link.href)).host.toLowerCase();
  if (!linkopenoptions.internalhosts.includes(destdomain))
    return true;

  if (linkopenoptions.extensions) {
    const ext = link.href.split('?')[0].split('#')[0].split('.').at(-1);
    if (ext && linkopenoptions.extensions.find(match => match.toUpperCase() == ext.toUpperCase()))
      return true;
  }

  return false;
}


export function addExternalLinkClassToAnchors()
{
  dompack.register(".rtdcontent a", doUpdateLinkState);
}

function doUpdateLinkState(link)
{
  // console.log(linkIsExternal(link), link.href);
/*
  if (link.classList.contains())
    return;
*/
  if (linkIsExternal(link))
  {
    link.classList.add("isexternal");

    /* We add an element to:
       - Show an "external link" icon which we can ensure isn't affected by the underlien
       -
    */
  }
}



export function openLinksInNewWindow(options) {
  if (!openLinksInNewWindow.attached) {
    openLinksInNewWindow.attached = true;
    //IE11 fails sometimes (mostly, when navigating to the page but never when using F5, the back/forward page cache must be involved) to actually attach this element
    window.addEventListener("click", onLinkClick);
  }
  linkopenoptions = { ...options };

  if (!linkopenoptions.internalhosts) {
    const ourdomain = (new URL(location.href)).host.toLowerCase();
    if (ourdomain.substr(0, 4) == 'www.')
      linkopenoptions.internalhosts = [ourdomain, ourdomain.substr(4)];
    else
      linkopenoptions.internalhosts = [ourdomain, 'www.' + ourdomain];
  }
}

function onLinkClick(event)
{
  const link = event.target.closest('a');
  if (linkIsExternal(link))
    link.target = "_blank";
}
