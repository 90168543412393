import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-zvi/webdesigns/shared/components/filteredoverview";
import { onExpandFilters, onCollapseFilters } from "@mod-zvi/webdesigns/shared/components/filteredoverview/filter-dialog";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";


dompack.register("html.page--eventsoverview .acoifilterpage-filters", initEventsOverview);


let filterform;
let resultscontainer;

function initEventsOverview(formnode)
{
  filterform = formnode;
  resultscontainer = document.querySelector(".acoifilterpage__results");

  let text = document.querySelector(".acoifilterpage").getAttribute("data-hiddenfilters");
  let hiddenfilters = JSON.parse(text);

  let settings =
          { trackingid:             "filtered_eventsoverview" // name for analytics (and debug)
          , statestorageid:         "filtered_eventsoverview" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".spc-tile")
          // , getfloatingteasertext:  getFloatingTeaserText

          , hiddenfilters:          hiddenfilters
          , fields_notforfiltering: ["restricturl", "folderid", "langcode"] // only meant for RPC

          , method:                 "clientside"
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , rpcsearchfunction:      "FindItemMatches"
          , fields_rpc:             ["query"]

          , getfiltersforrpc:       getFiltersForRPC
          , onafterrefresh:         onAfternewResults

          , resultsperpage:         15

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters

          , debug:                  false
          , debug_rpc:              false
          };

  window.filteredoverview_events = new FilteredOverview(formnode, settings);
  window.filteredoverview_events.refreshResults();
}


function onAfternewResults()
{
  let instance = window.filteredoverview_events;

  /*
  console.log(instance.filters);

  let resultscount = instance.resultscount;
  let query = instance.filters.query         ?? "";
  let thema = instance.filters_titles.themas ?? "";

  // FIXME: geef een veld door in instance.last_rpcresponse voor aantal resultaten zonder te filteren op pagina type??

  let text_amount;
  if (resultscount > 1)
    text_amount = `Er zijn <strong>${resultscount} resultaten</strong>`;
  else if (resultscount == 1)
    text_amount = `Er is één resultaat`;
  else
    text_amount = `Er zijn geen resultaten`;

  let tagtitles = [];
  for(let tagrec of instance.tags)
  {
    console.info(tagrec);
    tagtitles.push(tagrec.title);
  }
  text_describe_query = "voor " + tagtitles.join(", ");

  let feedback_text = `${text_amount} ${text_describe_query}`;

  document.querySelector(".acoifilterpage-zvi-feedback").innerHTML = feedback_text;
  // console.log(feedback_text);
  */
}


// change the tags to an integer array
function getFiltersForRPC(filters)
{
  // console.info("FILTERS", filters);

  if ("tags" in filters)
    filters.tags = convertToIntegerArray(filters.tags);

  if ("themas" in filters)
    filters.themas = convertToIntegerArray(filters.themas);

  // console.info("UPDATED", filters);

  return filters;
}


function convertToIntegerArray(filtervalue)
{
  let tags = [];
  if (Array.isArray(filtervalue))
  {
    for (let tag of filtervalue)
      tags.push(parseInt(tag));
  }
  else
    tags.push(parseInt(filtervalue));

  return tags;
}
