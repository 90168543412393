import * as dompack from "dompack";
import "./divider.css";

dompack.register(".widget--divider", doHandleDivider);


function doHandleDivider(node)
{
  let before = node.previousElementSibling;
  let after = node.nextElementSibling;

  let before_comp = getComputedStyle(before);
  let after_comp = getComputedStyle(after);

  let before_color = before_comp.getPropertyValue("--theme-name").trim();
  let after_color = after_comp.getPropertyValue("--theme-name").trim();


  let before_layout = getComputedStyle(before).getPropertyValue("--content-layout").trim();
  let after_layout = getComputedStyle(after).getPropertyValue("--content-layout").trim();

  // node.setAttribute("beforecolor", before_color);
  // node.setAttribute("aftercolor", after_color);

  if (before_color == "lightgrey" && after_color == "lightgrey")
  {
    node.classList.add("divider--white");
    node.classList.add("divider--whitespace");
  }
  else if (before_color != "" && after_color != "")
  {
    // we don't need a divider line due to the contrast of different background colors
    node.classList.add("divider--white");

    //if (before_color != after_color)
  }
  else if (before_color == "" && after_color == "")
  {
    node.classList.add("divider--grey");
    node.classList.add("divider--whitespace");
  }
  else
    node.classList.add("divider--inactive");

  /*
  console.info("Divider", node);
  console.log("Before", before, before_color, before_layout);
  console.log("After", after, after_color, after_layout);
  */

//  console.log(node, before_layout, after_layout);
}
