import "./news-overview.scss";

import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-zvi/webdesigns/shared/components/filteredoverview";
import { onExpandFilters, onCollapseFilters } from "@mod-zvi/webdesigns/shared/components/filteredoverview/filter-dialog";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";
//import * as whintegration from "@mod-system/js/wh/integration";


dompack.register("html.page--newsoverview2 .acoifilterpage-filters", initNewsOverview);


let filterform;
let resultscontainer;

function initNewsOverview(formnode)
{
  filterform = formnode;
  resultscontainer = document.querySelector(".acoifilterpage__results");

  let text = document.querySelector(".acoifilterpage").getAttribute("data-hiddenfilters");
  let hiddenfilters = JSON.parse(text);

  let settings =
          { trackingid:             "filtered_newsoverview" // name for analytics (and debug)
          , statestorageid:         "filtered_newsoverview" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".spc-tile")
          // , getfloatingteasertext:  getFloatingTeaserText

          , hiddenfilters:          hiddenfilters
/*
Serverside news retrieval
          , method:                 "rpc"
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , rpcsearchfunction:      "SearchNews"
          , getfiltersforrpc:       getFiltersForRPC
          , onafterrefresh:         onAfternewResults
*/
          , method:                 "clientside"
          , rpc:                    new JSONRPC({ url: "/wh_services/zvi/rpc/" })
          , fields_rpc:             ["query"]
          , fields_rpc_hints:       ["restricturl", "folderid", "langcode"]
          , rpcsearchfunction:      "FindItemMatches"
          // , fields_notforfiltering: ["folderid", "langcode"]


          , resultsperpage:         20

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters

          , debug:                  false
          , debug_rpc:              true
          };

  window.filteredoverview_news = new FilteredOverview(formnode, settings);

  if (window.filteredoverview_news.isFilterActive())
    window.filteredoverview_news.refreshResults(false);
}


function onAfternewResults()
{
  let instance = window.filteredoverview_news;

  //console.log(instance.filters);

  let resultscount = instance.resultscount;
  let query = instance.filters.query        ?? "";
  let thema = instance.filters_titles.thema ?? "";

  // FIXME: geef een veld door in instance.last_rpcresponse voor aantal resultaten zonder te filteren op pagina type??

  let text_amount;
  if (resultscount > 1)
    text_amount = `Er zijn <strong>${resultscount} resultaten</strong>`;
  else if (resultscount == 1)
    text_amount = `Er is één resultaat`;
  else
    text_amount = `Er zijn geen resultaten`;

  let tagtitles = [];
  for(let tagrec of instance.tags)
  {
    // console.info(tagrec);
    tagtitles.push(tagrec.title);
  }
  text_describe_query = "voor " + tagtitles.join(", ");

  let feedback_text = `${text_amount} ${text_describe_query}`;

  document.querySelector(".acoifilterpage-zvi-feedback").innerHTML = feedback_text;
  // console.log(feedback_text);




  let container = window.filteredoverview_news.options.node_results;
  if (!container)
    return;

  let fallbackimage = container.getAttribute("data-fallbackimage");
  let fallbackimage_refpos = container.getAttribute("data-fallbackimage-position");

  // console.info("Adding fallback images to results.");

  // Add fallback images to items without image
  for (let item of container.children)
  {
    let image = item.querySelector(".spc-tile__image");

    // console.log(item, image);

    if (image.classList.contains("spc-tile__image--empty"))
    {
      // console.log("Adding fallback image");
      let newimage = document.createElement("img");
      newimage.src = fallbackimage;
      newimage.className = "spc-tile__image";

      if (fallbackimage_refpos)
        newimage.style.objectPosition = fallbackimage_refpos;

      image.parentNode.replaceChild(newimage, image);

      item.classList.remove("spc-tile--noimage");
      item.classList.add("spc-tile--fallbackimage");
      //spc-tile__image--empty
    }

  }

}


// change the tags to an integer array
function getFiltersForRPC(filters)
{
  if ("soort" in filters)
  {
    filters.tags = convertToIntegerArray(filters.soort);
    delete filters.soort;
  }

  if ("thema" in filters)
    filters.thema = convertToIntegerArray(filters.thema);

  return filters;
}


function convertToIntegerArray(filtervalue)
{
  let tags = [];
  if (Array.isArray(filtervalue))
  {
    for (let tag of filtervalue)
      tags.push(parseInt(tag));
  }
  else
    tags.push(parseInt(filtervalue));

  return tags;
}
