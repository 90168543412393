import "./podcast.css";
import * as dompack from "dompack";


dompack.register(".podcastwidget__description__readmorebutton", initToggle);

function initToggle(node)
{
  node.addEventListener("click", evt => doToggle(evt));
}

function doToggle(evt)
{
  let container = evt.target.closest(".widget");
  container.classList.toggle("widget--readmore-activated")
}



export class PodcastWidget
{
  constructor(node)
  {
    console.info("[PodcastWidget] initialize");

    // Important DOM nodes for this widget
    this.node = node;
    this.node_timeline = node.querySelector(".podcastwidget__timeline");
    this.node_currenttime = node.querySelector(".podcastwidget__time-current");

    // State
    this.isplaying = false;

    node.querySelector(".podcastwidget__playbutton").addEventListener("click", evt => this.togglePlayback(evt));

    //node.style.setProperty("--podcast-playback-position-percentage", "5%");

    let audiolink = node.getAttribute("data-audiofilelink");
    console.log("audio file link:", audiolink);

    //this.audio = new Audio(audiolink);
    this.audio = document.createElement("audio");
    this.audio.setAttribute("preload", "none");
    this.audio.setAttribute("src", audiolink);
    this.audio.addEventListener("timeupdate",     evt => this.onTimelinePositionUpdate(evt));
    this.audio.addEventListener("loadedmetadata", evt => this.onLoadedMetadata(evt));

    this.audio.addEventListener("pause", evt => this.onOtherEvent(evt));
    this.audio.addEventListener("play",  evt => this.onOtherEvent(evt));
    this.audio.addEventListener("ended", evt => this.onOtherEvent(evt));

    this.node_timeline.addEventListener("change", evt => this.onTimelineSeek(evt));
  }


  onOtherEvent(evt)
  {
    console.log(evt.type, evt);

    if (evt.type == "ended" || evt.type == "pause")
    {
      this.isplaying = false;
      this.node.classList.remove("podcastwidget--playing");
    }
    else if (evt.type == "play")
    {
      this.isplaying = true;
      this.node.classList.add("podcastwidget--playing");
    }
  }

  togglePlayback()
  {
    console.info("[PodcastWidget] togglePlayback");
    this.isplaying = !this.isplaying;

    this.node.classList.toggle("podcastwidget--playing");
    this.node.classList.add("podcastwidget--hasbeenplaying");

    if (this.isplaying)
      this.audio.play();
    else
      this.audio.pause();
  }

  onTimelineSeek(evt)
  {
    if(this.audio.readyState == 0) // != HAVE_NOTHING)
      return;

    const time = (this.node_timeline.value * this.audio.duration) / 100;
    this.audio.currentTime = time;
  }

  onLoadedMetadata(evt)
  {
    document.querySelector(".podcastwidget__time-duration").innerText = this.getTimeText(this.audio.duration);
  }

  onTimelinePositionUpdate(evt)
  {
    if (this.node_timeline.matches(":active"))
      return;

    //console.log("time update", evt);
    const percentagePosition = (100 * this.audio.currentTime) / this.audio.duration;
    // timeline.style.backgroundSize = `${percentagePosition}% 100%`;
    this.node_timeline.value = percentagePosition;

    this.node.style.setProperty("--podcast-playback-position-percentage", percentagePosition + "%");

    this.node_currenttime.innerText = this.getTimeText(this.audio.currentTime);
  }


  getTimeText(time_secs)
  {
    let hours   = Math.floor(time_secs / 3600);
    let minutes = Math.floor(time_secs % 3600 / 60);
    let seconds = Math.round(time_secs %   60);

    return   (hours > 0 ? (hours > 9 ? hours+":" : "0"+hours+":") : "")
           + (minutes > 9 ? minutes : "0"+minutes)
           + ":"
           + (seconds > 9 ? seconds : "0"+seconds);
  }
}


dompack.register(".widget--podcast", initPodcast);

function initPodcast(node)
{
  new PodcastWidget(node);
}
