/*
  // If we use Tippy we have tooltips
  tippy('[data-tippy-content]'); // handle custom info buttons in the witty
*/


// From "Week van het Geld"
import * as dompack from "dompack";
import tippy from 'tippy.js';
import "tippy.js/dist/tippy.css";
//import "./tooltip.scss";
import "./infobutton.css";


dompack.onDomReady(initTooltips);


/*
// The ActaVerba tools way:
    tippy( new_info.querySelector("button")
         , { content: moreinfo.innerHTML
           , allowHTML: true
         //, hideOnClick: false // set to false for testing
           , trigger: 'mouseenter click'
           });
    moreinfo.parentNode.removeChild(moreinfo); // remove the original moreinfo node
*/

function initTooltips()
{
  // tippy('.infobutton');
  // tippy( 'button[data-tippy-template]'
  tippy( '.infobutton'
       , { content(reference)
           {
             let id = reference.getAttribute('data-tippy-template');
             //let id = reference.getAttribute('aria-describedby');
             let node = document.getElementById(id);
             return node;
           }

         //, trigger: "mouseenter focus"
         , trigger: "click" // also because we allow links within the tooltip content

         , theme: "infobutton-tooltip"
         , interactive: true // we have <a> inside

        /* To inspect the contents of the tooltip you can enable
           these settings so the tooltip won't disappear when you try to inspect it:
        , trigger: "click"
        , hideOnClick: false
       */
         }
        );

}


/* WVHG way */
function initTooltipsWVHG()
{
  // Handle plaintext tooltips
  tippy('[data-tippy-content]');

  // Handle richtext tooltips
  tippy( 'button[data-tippy-template]',
  // tippy( 'button[aria-controls]',
       { content(reference)
         {
           const id = reference.getAttribute('data-tippy-template');
           //const id = reference.getAttribute('aria-controls');

           /*
           const linkedTemplate = document.getElementById(id);
           return linkedTemplate.innerHTML;
           */

           const container = document.createElement('div');
           container.className = "rtdcontent";
           const linkedTemplate = document.getElementById(id);
           const node = document.importNode(linkedTemplate.content, true);
           container.appendChild(node);
           return container;
         }

       // , trigger: "click"
       // , trigger: "mouseenter focus"
       , interactive: true

       , trigger: "focus"
       , hideOnClick: false
     //, allowHTML: true
     });
}
