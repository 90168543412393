//We are the only videojs user right now, so keep it all here:

import * as dompack from 'dompack';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import toWebVTT from "./srt-webvtt"; // This is a default export, so you don't have to worry about the import name
import ToggleCaption from './togglecaption';

import './rovid.scss';
import './rovid-controls.scss';


class VideoPlayer
{
  constructor(node)
  {
    this.node = node;

    const elVideo = node.querySelector("video");

    this.videoplayer = new videojs(elVideo,
        { controls: true
        , preload: 'auto'
        , aspectRatio: '16:9'
        });

    // remove the normal subtitles button
    const controlBar = this.videoplayer.getChild('ControlBar');
    controlBar.removeChild(controlBar.getChild('SubsCapsButton'));

    // setup subtitles
    this.videoplayer.on('ready', async() =>
    {
      const srtURL = elVideo.dataset ? elVideo.dataset.srt : '';
      if (srtURL)
      {
        console.info("Adding subtitles track", srtURL);
        this.addRemoteTextTrackBySRT(srtURL,
            { button:        node.querySelector('.vjs-captions-toggle.cc')
            , type:          'subtitles'
            , className:     'cc'
            , iconText:      'CC'
            , title:         'Ondertiteling' // This is the text screenreaders will use
            , manualCleanup: false
            });
      }

      /* audio descriptions closed captions
         "Audio description narrates the crucial visual elements that would be necessary for comprehension without the ability to see the screen. It describes non-verbal cues like gestures, facial expressions, or eye contact, as well as objects or scenery that’s important to understanding the plot. In an educational lecture, audio description can be used to describe charts, graphs, and diagrams."
      */
      const adURL = elVideo.dataset ? elVideo.dataset.adsrt : '';
      if (adURL)
      {
        console.info("Adding AD closed captions track", srtURL);
        this.addRemoteTextTrackBySRT(adURL,
            { button:        node.querySelector('.vjs-captions-toggle.ad')
            , type:          'captions'
            , className:     'ad'
            , iconText:      'AD'
            , title:         'Audiodescriptie'
            , manualCleanup: false
            });
      }
    });
  }

  // adds a track and a button to enable it
  async addRemoteTextTrackBySRT(srtURL = '', options = {})
  {
    const webVTTURL = await this.convertSrtURLToWebVTT(srtURL);

    if (webVTTURL)
    {
      this.videoplayer.addRemoteTextTrack(
          { kind: options.type
          , src: webVTTURL
          , mode: 'hidden'
          });

      const controlBar = this.videoplayer.getChild('ControlBar');
      controlBar.addChild(new ToggleCaption(this.videoplayer, {}, options, this.node));
    }
  }

  // returns a DOMString containing a URL to a WebVTT file (converted from SRT)
  async convertSrtURLToWebVTT(url = '')
  {
    /*
    const srtBlob = await fetch(url).then(r => r.text());
    console.log("Received", srtBlob);
    const convertedWebVTT = srt2webvtt(srtBlob);
    console.log("Converted to WebVTT", convertedWebVTT);

    const vttBlob = new Blob([convertedWebVTT], {type : 'text/vtt'});
    return URL.createObjectURL(vttBlob);
    */
    const srtBlob = await fetch(url).then(r => r.blob());
    const vttBlobURL = toWebVTT(srtBlob);
    return vttBlobURL;

  }
}

dompack.register(".widget--rovid", (node) => new VideoPlayer(node));
